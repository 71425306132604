import { BrowserRouter, Route, Routes } from "react-router-dom";
import Page from "./pages/Page";
import Samansa from "./pages/workSamansa";
import Meditel from "./pages/workMeditel";
import Monorevo from "./pages/workMonorevo";
import Rentme from "./pages/workRentme";
import Noones from "./pages/workNoones";
import Alphanote from "./pages/workAlphanote";
import Penmark from "./pages/workPenmark";
import Buttons from "./DesignSystem/Buttons";
import Inputs from "./DesignSystem/Inputs";
import WorkTemp from "./ui/comp/WorkTemp";
import React, { useEffect, useRef, useState } from "react";
// import "../../styles/main.scss";
// import "../../styles/designSys.scss";
import HoverIcon from "./ui/atom/Icon";
import BtnDef from "./ui/atom/Btn";
import { useLocation } from "react-router-dom";
import CustomIcon from "./data/IconCompo";
import MobileMenu from "./ui/comp/MobileMenu";
import { createRoot } from "react-dom/client";
import Hafid from "./pages/hafid";

// Define the App component
const App = () => {
  const [mobileMenu, setMobileMenu] = useState(false);
  const [scrollpos, setScrollpos] = useState(false);
  useEffect(() => {
    if (mobileMenu) {
      document.querySelector("body").style.height = "100vh";
      document.querySelector("body").style.overflowY = "hidden";
    } else {
      console.log(scrollpos);
      document.querySelector("body").style.height = "auto";
      document.querySelector("body").style.overflowY = "scroll";
      document.querySelector("html").scrollTo({
        top: scrollpos,
        left: 0, // If you want to scroll horizontally, you can set a value here
        // Use smooth scrolling behavior
      });
    }
  }, [mobileMenu]);
  return (
    <React.StrictMode>
      <BrowserRouter>
        <Routes>
          {/* Define routes for each path */}
          <Route
            path="/"
            element={
              <Page
                setMobileMenu={(res) => setMobileMenu(res)}
                mobileMenu={mobileMenu}
                scrollpos={scrollpos}
                setScrollpos={(res) => setScrollpos(res)}
              />
            }
          />
          <Route
            path="home/:section"
            element={
              <Page
                setMobileMenu={(res) => setMobileMenu(res)}
                mobileMenu={mobileMenu}
                scrollpos={scrollpos}
                setScrollpos={(res) => setScrollpos(res)}
              />
            }
          />
          <Route path="/buttons" element={<Buttons />} />
          <Route path="/inputs" element={<Inputs />} />
          <Route
            path="/worktemp"
            element={
              <WorkTemp
                setMobileMenu={(res) => setMobileMenu(res)}
                mobileMenu={mobileMenu}
                scrollpos={scrollpos}
                setScrollpos={(res) => setScrollpos(res)}
              />
            }
          />
          <Route
            path="/samansa"
            element={
              <Samansa
                setMobileMenu={(res) => setMobileMenu(res)}
                mobileMenu={mobileMenu}
                scrollpos={scrollpos}
                setScrollpos={(res) => setScrollpos(res)}
              />
            }
          />
          <Route
            path="/meditel"
            element={
              <Meditel
                setMobileMenu={(res) => setMobileMenu(res)}
                mobileMenu={mobileMenu}
                scrollpos={scrollpos}
                setScrollpos={(res) => setScrollpos(res)}
              />
            }
          />
          <Route
            path="/monorevo"
            element={
              <Monorevo
                setMobileMenu={(res) => setMobileMenu(res)}
                mobileMenu={mobileMenu}
                scrollpos={scrollpos}
                setScrollpos={(res) => setScrollpos(res)}
              />
            }
          />
          <Route
            path="/noones"
            element={
              <Noones
                setMobileMenu={(res) => setMobileMenu(res)}
                mobileMenu={mobileMenu}
                scrollpos={scrollpos}
                setScrollpos={(res) => setScrollpos(res)}
              />
            }
          />
          <Route
            path="/alphanote"
            element={
              <Alphanote
                setMobileMenu={(res) => setMobileMenu(res)}
                mobileMenu={mobileMenu}
                scrollpos={scrollpos}
                setScrollpos={(res) => setScrollpos(res)}
              />
            }
          />
          <Route
            path="/penmark"
            element={
              <Penmark
                setMobileMenu={(res) => setMobileMenu(res)}
                mobileMenu={mobileMenu}
                scrollpos={scrollpos}
                setScrollpos={(res) => setScrollpos(res)}
              />
            }
          />
          <Route
            path="/rentme"
            element={
              <Rentme
                setMobileMenu={(res) => setMobileMenu(res)}
                mobileMenu={mobileMenu}
                scrollpos={scrollpos}
                setScrollpos={(res) => setScrollpos(res)}
              />
            }
          />
          <Route
            path="/about"
            element={
              <Rentme
                setMobileMenu={(res) => setMobileMenu(res)}
                mobileMenu={mobileMenu}
                scrollpos={scrollpos}
                setScrollpos={(res) => setScrollpos(res)}
              />
            }
          />
          <Route path="/me" element={<Hafid />} />
        </Routes>
        {mobileMenu && (
          <div className="mobile-menu">
            <MobileMenu
              setMobileMenu={(res) => setMobileMenu(res)}
              mobileMenu={mobileMenu}
              scrollpos={scrollpos}
              setScrollpos={(res) => setScrollpos(res)}
            >
              <BtnDef
                className="close"
                onClick={() => {
                  setMobileMenu(false);
                }}
              >
                <CustomIcon iconName={"close"} />
              </BtnDef>
            </MobileMenu>
          </div>
        )}
      </BrowserRouter>
    </React.StrictMode>
  );
};

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

// Render the App component
root.render(<App />);
