// import CustomIcon from "../data/IconCompo";
import "../styles/main.scss";
import "../styles/designSys.scss";
import React, { useState } from "react";
import InputSelectData from "../ui/atom/InputSelectData";
import Header from "../ui/comp/Header";
import SectionCard from "../ui/comp/SectionCard";
import TopSection from "../ui/comp/TopSection";
import SubSectionCard from "../ui/comp/SubSectionCard";
import SubCard from "../ui/comp/SubCard";
import WorkCard from "../ui/comp/WorkCard";
import SloganCrad from "../ui/comp/SloganCard";
import WorkStyleCard from "../ui/comp/WorkStyleCard";
import Footer from "../ui/comp/Footer";
import TeamCard from "../ui/comp/TeamCard";
import WorkTemp from "../ui/comp/WorkTemp";
import WorkTitle from "../ui/atom/works/workTitle";
import WorkDetailCard from "../ui/atom/works/WorkDetailCard";

function Home({ setMobileMenu, mobileMenu, scrollpos, setScrollpos }) {
  return (
    <div className="work-details-hero rentme-details-hero">
      {/* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: HEADER :::::::: */}
      <Header
        className="header-light"
        setMobileMenu={(res) => setMobileMenu(res)}
        mobileMenu={mobileMenu}
        scrollpos={scrollpos}
        setScrollpos={(res) => setScrollpos(res)}
      />
      {/* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: WORK DETAILS :::::::: */}
      <WorkTitle
        title="RENTME"
        category="不動産"
        content="
        <p>
        伝統的な不動産エージェントを必要とせず、日本での家の賃貸プロセスをスムーズにする革新的なプロジェクトです。 
        この時代には、仲介手数料がゼロであることが期待されています。 <br/>
        ユーザーが使いやすいモバイルアプリを通じて家を探し、訪問し、契約することができるサービスを先駆けとして開発しています。        
        </p>"
        image="rentme_top@2x.png"
        tags={["UX Design", "UI Design", "Branding"]}
        tags2={["Web Application", "Web", "App", "Creative Direction"]}
        tags3={[
          "Research",
          "Customer Journey",
          "Userflow",
          "Persona",
          "Wireframe",
          "Prototype",
          "Usability Test",
          "その他",
        ]}
      />
      <WorkDetailCard
        className="color-01"
        icon=""
        // className={"text-only"}
        title="The Challenge"
        content="<p>
        Rentmeプロジェクトにおける主な課題は、従来の不動産エージェントに依存した日本の賃貸プロセスを、未知のオンライン体験を導入することで革新することでした。
        これには、複雑な手続きを単純化し、言語の壁を減らし、オンライン賃貸に慣れていないユーザーの直感的なナビゲーションを確保することが含まれます。              
              </p>
             "
        title2="The Solution"
        content2="<p>
        課題に対処するために、ユーザー調査を行い、ワイヤフレームやプロトタイプを作成して、ユーザーインターフェースを改善しました。明確で直感的なナビゲーションを確保するために、カードソーティングやユーザーフローマッピングを活用し、一貫したビジュアルデザインを導入しました。
        これらの手法により、Rentmeはユーザーにシームレスで直感的なオンライン賃貸体験を提供し、従来のプロセスからの移行をスムーズにしました。              
        </p>
              "
      />
      <WorkDetailCard
        className="color-01"
        category="Design"
        title="Branding"
        content="<p>
        このブランディングは、部屋探しに関する概要を示すロゴデザインを特徴としています。<br/>
        最初に、クライアントとのブレインストーミングやヒアリングを通じて、初期のアイデアをスケッチしました。<br/>
        最終的な結果は、部屋探しに関する一見のアイデアを示しており、明るい色彩を使用して、サービスを利用する際の信頼感や自信をユーザーに与えるようにしています。
        </p>"
        image="rentme_branding@2x.png"
      />
      <WorkDetailCard
        className="color-02"
        category="Design"
        title="UI Design"
        content="
        <p>
        このUIデザインは、ユーザーエクスペリエンスとユーザーインターフェースの設計を通じて、利用者がスムーズにサービスを利用できるように工夫されています。<br/>
        まず、オンボーディングプロセスでは、ユーザーが簡単にサービスの機能や利点を理解し、登録手続きを円滑に進められるようにしました。また、部屋を訪問するプロセスでは、直感的なナビゲーションと視覚的なガイダンスを提供し、ユーザーが目的地に迷うことなく進むことができます。<br/>
        さらに、賃貸契約の署名プロセスでは、シンプルでわかりやすいステップを提供し、ユーザーが煩雑な手続きなしに契約を完了できるようにしました。
        </p>"
        image="rentme_ui@2x.png"
      />

      {/* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: HEADER :::::::: */}
      <Footer />
    </div>
  );
}

export default Home;
