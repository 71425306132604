import "../../styles/main.scss";
import "../../styles/temp.scss";
import Input from "../atom/Input";
import Button from "../atom/Button";
import Select from "../atom/Select";
import { useEffect, useState, useRef } from "react";
import TextAreaSimple from "../atom/TextAreaSimple";
import { contactFormValidation } from "../../validation";
// import { BACKEND_URL } from "../../config";
import axios from "axios";

export default function ContactForm({ setInfoPopupData, setSuccessPopup }) {
  const [errors, setErrors] = useState({});
  const formRef = useRef(null);
  const [data, setData] = useState({});
  const [desc, setDesc] = useState();

  const handleChange = async (element, dataP) => {
    const newData = { ...data, [`${element}`]: dataP };
    setData(newData);
    console.log(newData);
    if (errors[element]) {
      errors[element] = false;
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Assuming you have the necessary data to send in your request
    setErrors(contactFormValidation(data));
    let x = contactFormValidation(data);
    // Assuming you have the necessary data to send in your request
    if (Object.keys(x).length === 0) {
      //   showInfoPopup("success", "Connecting!...", 4000, "right_arrow_ani");
      setInfoPopupData({
        text: "送信中!...",
        type: "success",
        time: 4000,
        iconName: "right_arrow_ani",
      });

      axios
        .post(
          `https://api.pinjob.me/api/juusando/contact`,
          {
            name: data?.name,
            email: data?.email,
            phone: data?.phone,
            desc: data?.desc,
            subject_title: data?.subject_title?.label,
            company: data?.company,
          },
          {}
        )
        .then((res) => {
          setData({});
          setDesc({});
          setInfoPopupData(false);
          setSuccessPopup({
            text: (
              <div dangerouslySetInnerHTML={{ __html: "メッセージが正常に送信されました。<br/> できるだけ早くご連絡いたします" }} />
            ),            title: "Thank you!",
          });

          //       showInfoPopup(false);
        })
        .catch((err) => {
          console.log(err);
          //    showInfoPopup("error", "Please try again later");
          setInfoPopupData({
            text: x[Object.keys(x)[0]],
            type: "error",
          });
        });
    } else {
      if (Object.keys(x).length === 1) {
        //        showInfoPopup("error", x[Object.keys(x)[0]]);
        setInfoPopupData({
          text: x[Object.keys(x)[0]],
          type: "error",
        });
      } else if (Object.keys(x).length >= 1) {
        setInfoPopupData({
          text: "必須項目を適切に入力してください!",
          type: "error",
        });
      }
    }
  };
  useEffect(() => {
    if (data?.remote?.value == "remote") {
      setErrors((prev) => {
        if (prev?.country || prev?.city) {
          delete prev?.country;
          delete prev?.city;
        }
        return { ...prev };
      });
      setData((prev) => {
        if (prev?.country || prev?.city) {
          delete prev?.country;
          delete prev?.city;
        }
        return { ...prev };
      });
    }
  }, [data?.remote?.value]);

  return (
    // ----------------------------------------------------------------------------------
    <>
      <div className=" contact-form-box">
        <form onSubmit={handleSubmit} ref={formRef} method="post">
          <div className="contact_inputs_box">
            <Input
              placeholder=""
              className="input_def "
              label="お名前"
              onChange={(e) => handleChange("name", e)}
              value={data?.name}
              error={errors?.name}
            />
            <Input
              placeholder=""
              className="input_def "
              label="メールアドレス"
              onChange={(e) => handleChange("email", e)}
              value={data?.email}
              error={errors?.email}
            />
            <Input
              placeholder=""
              className="input_def "
              label="会社名"
              onChange={(e) => handleChange("company", e)}
              value={data?.company}
              error={errors?.company}
            />
            <Input
              placeholder=""
              className="input_def "
              label="電話番号（オプション）"
              onChange={(e) => {
                if (/^-?\d*\.?\d+$/.test(e) || e.length == 0) {
                  handleChange("phone", e);
                }
              }}
              value={data?.phone}
              error={errors?.phone}
            />
          </div>

          <div className="contact_inputs_box">
            <Select
              placeholder=""
              defaultIconName="drop_down"
              focusedIconName="drop_up"
              className="signup_post_input"
              label="お問い合わせの件名"
              options={[
                { label: "サービスに関するご質問", value: "Question about Services" },
                { label: "案件の依頼または相談について", value: "Project Inquiry / Consultation" },
                { label: "UX/UIデザインのコンサルタントに関する相談", value: "UX/UI Design Consultation" },
                { label: "見積もりや要件について", value: "Estimation / Requirements" },
              ]}
              
              setSelectedOption={(e) => handleChange("subject_title", e)}
              selectedOption={data?.subject_title}
              error={errors?.subject_title}
            />

            <TextAreaSimple
              className=""
              label={"メッセージ"}
              placeholder=""
              onChange={(e) => {
                handleChange("desc", e);
              }}
              value={data?.desc}
              error={errors?.desc}
              textStyle={false}
              innerClass={"textarea-input-cont"}
            />
          </div>

            <Button
              text="送信"
              icon1="send"
              icon2="send_ani"
              className="send_button"
              onClick={() => {}}
            />
        </form>
      </div>
    </>

    // -----------------------------------------------------------------------------
  );
}
