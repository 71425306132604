// import CustomIcon from "../data/IconCompo";
import "../styles/hb13.webflow.css";
import "../styles/normalize.css";
import "../styles/webflow.css";
import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";

function Hafid({ setMobileMenu, mobileMenu, scrollpos, setScrollpos }) {
  const location = useLocation();

  return (
    <div className="body">
      <div class="frame-hiro">
        <div class="tl-title-box top">
          <a href="#Top" class="close-big-box home w-inline-block">
            <div class="close-icn w-embed">
              {/* <svg
                viewbox="0 0 33 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.5 3L4.5 18M4.5 18L19.5 33M4.5 18H30.5"
                  stroke="CurrentColor"
                  stroke-width="5"
                  stroke-linecap="round"
                ></path>
              </svg> */}
            </div>
            <div class="text-block-232">BACK</div>
          </a>
          <a id="hide" href="/" class="close-big-box w-inline-block">
            <div class="close-icn w-embed">
              <img
                src="left_arrow.svg"
                loading="lazy"
                alt=""
                // class="girs-card-img"
              />
            </div>
            <div class="text-block-232">BACK</div>
          </a>
          <div class="tmln-event-top-2">
            <div class="tmln-avatar-left-box">
              <img
                src="Orange---Avatar.png"
                loading="lazy"
                width="180"
                alt=""
                class="tl-avatar"
              />
              <div class="tmln-date-dot-box top">
                <img src="dot.svg" loading="lazy" alt="" class="image-221" />
              </div>
            </div>
            <div class="tmln-avatar-right-box">
              <div class="div-block-27">
                <div class="tmln-title-avatar-box">
                  <div class="text-block-22">
                    はふぃど
                    <br />
                    ぶるひむ
                  </div>
                </div>
                <div class="tmln-title-avatar-box">
                  <div class="en-w">
                    PRODUCT
                    <br />
                    DESIGNER
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tag-hiro menu">
          <div class="div-block-36">
            <a href="#Process" class="tag-box w-inline-block">
              <div class="tag">
                Process
                <br />
              </div>
              <div class="tag-jp">
                デザインプロセス
                <br />
              </div>
            </a>
            <a href="#Industry" class="tag-box w-inline-block">
              <div class="tag">
                Industry
                <br />
              </div>
              <div class="tag-jp">
                業界
                <br />
              </div>
            </a>
            {/*<!-- <a href="#Style" class="tag-box w-inline-block">
          <div class="tag">Work Style<br/></div>
          <div class="tag-jp">働き方<br/></div>
        </a> -->*/}
            <a href="#Strategy" class="tag-box w-inline-block">
              <div class="tag">
                Strategy
                <br />
              </div>
              <div class="tag-jp">
                戦略
                <br />
              </div>
            </a>
            <a href="#Method" class="tag-box w-inline-block">
              <div class="tag">
                UX Method
                <br />
              </div>
              <div class="tag-jp">
                UXメソッド
                <br />
              </div>
            </a>
            <a href="#Design" class="tag-box w-inline-block">
              <div class="tag">
                UI Design
                <br />
              </div>
              <div class="tag-jp">
                UIデザイン
                <br />
              </div>
            </a>
            <a href="#Wireframe" class="tag-box w-inline-block">
              <div class="tag">
                Wireframe
                <br />
              </div>
              <div class="tag-jp">
                ワイヤフレーム
                <br />
              </div>
            </a>
            <a href="#Prototype" class="tag-box w-inline-block">
              <div class="tag">
                Prototype
                <br />
              </div>
              <div class="tag-jp">
                プロトタイプ
                <br />
              </div>
            </a>
            <a href="#Branding" class="tag-box w-inline-block">
              <div class="tag">
                Branding
                <br />
              </div>
              <div class="tag-jp">
                ブランディング
                <br />
              </div>
            </a>
            <a href="#Interaction" class="tag-box w-inline-block">
              <div class="tag">
                Intraction
                <br />
              </div>
              <div class="tag-jp">
                インタラクション
                <br />
              </div>
            </a>
            <a href="#Education" class="tag-box w-inline-block">
              <div class="tag">
                Education
                <br />
              </div>
              <div class="tag-jp">
                デザイン教育
                <br />
              </div>
            </a>
            <a href="#Tools" class="tag-box w-inline-block">
              <div class="tag">
                Tools
                <br />
              </div>
              <div class="tag-jp">
                ツール
                <br />
              </div>
            </a>
          </div>
        </div>
        <div class="all-hiro">
          <div id="Process" class="tl-title-box">
            <div class="tmln-event-3">
              <div class="tl-title">
                <div class="text-block-28">UX DESIGN PROCESS!</div>
              </div>
              <div class="def-text">
                既存のプロジェクトの更新や新規開発において、「UXデザインプロセス」を活用しています。UXデザインプロセスのステップや内容、方法、ツールは、プロジェクトの種類、ターゲットユーザー、クライアントのニーズ、予算、スケジュール、リソースに応じて異なります。
                <br />
                <br />
                プロジェクトの要件や特性に合わせて、UXデザインプロセスを柔軟にカスタマイズし、主要な構造と機能を保ちながら適応させることが重要です。これにより、プロジェクト状況やクライアントのニーズ・要件に応じた最適なデザインが可能となります。
              </div>
              <img
                src="Process.png"
                loading="lazy"
                sizes="100vw"
                srcset="Process-p-500.png 500w, Process-p-800.png 800w, Process-p-1080.png 1080w, Process.png 1590w"
                alt=""
                class="image-223"
              />
              <img
                src="Process-1.png"
                loading="lazy"
                sizes="(max-width: 767px) 97vw, (max-width: 991px) 530px, 100vw"
                width="530"
                srcset="Process-1-p-500.png 500w, Process-1-p-800.png 800w, Process-1.png 964w"
                alt=""
                class="hideme"
              />
            </div>
          </div>
          <div id="Industry" class="tl-title-box">
            <div class="tmln-event-3">
              <div class="tl-title">
                <div class="text-block-28">
                  INDUSTIES!
                  <br />
                </div>
              </div>
              <div class="def-text">
                2006年にウェブデザイナーおよびクリエイティブディレクターとしてキャリアをスタートしました。これまでにUIおよびUXデザインのスキルを磨き、2010年以降はこれらの分野により焦点を当てたプロジェクトに取り組んできました。
                <br />
                <br />
                業界での16年の経験を通じて、個人プロジェクトから大企業、数多くのスタートアップに至るまで、幅広いプロジェクトに携わってきました。特に、ヘルスケア、フィンテック、テクノロジーなどさまざまな業界でのプロジェクトに取り組み、クライアントの独自のニーズを理解し、それに合わせたデザインアプローチを適用する貴重な経験を積んできました。
              </div>
              <div class="workk-box-3 industry">
                <div class="workk-image-hero meditel"></div>
              </div>
              <div class="ux-tag-hero">
                <a href="#" class="ux-tag-box w-inline-block">
                  <div>
                    FoodTech
                    <br />
                  </div>
                </a>
                <a href="#" class="ux-tag-box w-inline-block">
                  <div>
                    LP
                    <br />
                  </div>
                </a>
                <a href="#" class="ux-tag-box w-inline-block">
                  <div>
                    HealtCare
                    <br />
                  </div>
                </a>
                <a href="#" class="ux-tag-box bigger w-inline-block">
                  <div>
                    Startup
                    <br />
                  </div>
                </a>
                <a href="#" class="ux-tag-box w-inline-block">
                  <div>
                    EduTech
                    <br />
                  </div>
                </a>
                <a href="#" class="ux-tag-box w-inline-block">
                  <div>
                    Ecommerce
                    <br />
                  </div>
                </a>
                <a href="#" class="ux-tag-box bigger w-inline-block">
                  <div>
                    MVP
                    <br />
                  </div>
                </a>
                <a href="#" class="ux-tag-box w-inline-block">
                  <div>
                    Utilities
                    <br />
                  </div>
                </a>
                <a href="#" class="ux-tag-box w-inline-block">
                  <div>
                    Beauty
                    <br />
                  </div>
                </a>
                <a href="#" class="ux-tag-box bigger w-inline-block">
                  <div>
                    IT
                    <br />
                  </div>
                </a>
                <a href="#" class="ux-tag-box w-inline-block">
                  <div>
                    IoT
                    <br />
                  </div>
                </a>
                <a href="#" class="ux-tag-box w-inline-block">
                  <div>
                    toB
                    <br />
                  </div>
                </a>
                <a href="#" class="ux-tag-box bigger w-inline-block">
                  <div>
                    SaaS
                    <br />
                  </div>
                </a>
                <a href="#" class="ux-tag-box w-inline-block">
                  <div>
                    Real Estate
                    <br />
                  </div>
                </a>
                <a href="#" class="ux-tag-box bigger w-inline-block">
                  <div>
                    toC
                    <br />
                  </div>
                </a>
                <a href="#" class="ux-tag-box w-inline-block">
                  <div>
                    Gaming
                    <br />
                  </div>
                </a>
                <a href="#" class="ux-tag-box bigger w-inline-block">
                  <div>
                    Manufacturing
                    <br />
                  </div>
                </a>
                <a href="#" class="ux-tag-box w-inline-block">
                  <div>
                    FinTech
                    <br />
                  </div>
                </a>
                <a href="#" class="ux-tag-box w-inline-block">
                  <div>
                    Entertainment
                    <br />
                  </div>
                </a>
                <a href="#" class="ux-tag-box w-inline-block">
                  <div>
                    eSport
                    <br />
                  </div>
                </a>
                <a href="#" class="ux-tag-box w-inline-block">
                  <div>
                    Retail
                    <br />
                  </div>
                </a>
              </div>
            </div>
          </div>
          {/*  <!-- <div id="Style" class="tl-title-box">
        <div class="tmln-event-3">
          <div class="tl-title">
            <div class="text-block-28">Work Style!</div>
          </div>
          <div class="tmln-event-row-box no-point">
            <div class="def-text">
              数年間フリーランスとして働いた後、自分自身の会社を立ち上げました。これらの経験から、それぞれのアプローチの利点と欠点を学びました。<br/><br/>フリーランスとして、顧客とのより緊密な協力を楽しんでおり、サービス提供のすべての側面を自分で処理できる能力があり、これは大きな利点でした。<br/>一方、会社としては、より広範なデザイン要件に対応し、強い責任と規律を持つプロフェッショナリズムを維持することができました。<br/><br/>両方の世界のベストを組み合わせることを望み、過去のUX
              / UIデザイン会社としての経験を活かし、プロダクトデザイナー/
              UXUIデザイナーとしてクライアントチームと緊密に協力しています。<br/>これにより、アイデアから詳細なデザインまで、プロセスのすべての側面を自分で処理することで、一貫して優れた品質、迅速な高性能の作業を提供できます。<br/><br/>現在、<span
                class="freelancer">フリーランス</span>と<span
                class="company">デザイン会社</span>のそれぞれの長所を組み合わせたワークスタイルを提供しています。フリーランスの良いところと、会社スタイルの良いところを選び抜いたスタイルです</div>
            <div class="tmln-event-style-box-2">
              <div class="style-illu-box"><img src="company.svg" loading="lazy" width="51.5" alt=""
                  class="image-222"/></div>
              <div class="tmln-style-box">
                <div class="def-text dot">責任、規律、およびプロフェッショナリズム</div>
                <div class="def-text dot">幅広いデザインの範囲をカバー</div>
                <div class="def-text dot">提供する各サービスの豊富な経験</div>
                <div class="def-text dot">安全、安心</div>
                <div class="def-text dot">長期のサポートが受けられる</div>
              </div>
            </div>
            <div class="tmln-event-style-box-2">
              <div class="style-illu-box"><img src="freelancer.svg" loading="lazy" alt="" class="image-222"/>
              </div>
              <div class="tmln-style-box">
                <div class="def-text dot-blue">クライアントプロダクトチームと緊密に連携</div>
                <div class="def-text dot-blue">提供するすべてのUX/UIサービスの作業を自分自身で行う</div>
                <div class="def-text dot-blue">誰がすべての段階を通じてプロジェクトに取り組んでいるか明白</div>
                <div class="def-text dot-blue">デザイン会社と比較した妥当な価格</div>
                <div class="def-text dot-blue">要件とニーズに合わせて吸収される変更の容易さ(柔軟性)</div>
                <div class="def-text dot-blue">デザインプロセスにクライアントを巻き込む</div>
                <div class="def-text dot-blue">こよみに関係なく曜日を問わない柔軟な対応が可能</div>
              </div>
            </div>
          </div>
        </div>
      </div> -->*/}
          <div id="Strategy" class="tl-title-box">
            <div class="tmln-event-3">
              <div class="tl-title">
                <div class="text-block-28">Strategy!</div>
              </div>
              <div class="def-text">
                様々なプロジェクトでの長年の経験を通じて、異なる側面や要件が最終的なプロダクトの品質に大きな影響を与えることを理解しました。そのため、クライアントが自身のプロジェクトのコンセプト、ターゲット市場、およびユーザーを深く理解し、プロジェクトに適した計画、ロードマップ、戦略を策定するためのスキルと経験を習得しました。
                <br />
                <br />
                戦略立案に関する私のスキルと経験には、以下が含まれます：
                <span class="red">
                  SWOT/TOWS分析、MVV（ミッション、ビジョン、バリュー）ステートメントの開発、アクションプランの作成、競合分析、主要パフォーマンス指標（KPI）の特定、SMART目標の設定、プロジェクト目標の定義
                </span>
                など...
                <br />
                <br />
                これらのスキルやツールを活用し、クライアントが目標に合わせた包括的で効果的な戦略計画を開発し、成功の可能性を最大化できるようサポートしています。
              </div>
            </div>
          </div>
          <div id="Method" class="tl-title-box">
            <div class="tmln-event-3">
              <div class="tl-title">
                <div class="text-block-28">UX METHODS &amp; TOOLS!</div>
              </div>
              <div class="def-text">
                「UXデザインプロセス」の一般的な方法とツールに熟達しており、計画、準備、実行、分析の4つのステップを通じて適用しています。
                <br />
                <br />
                プロジェクトのニーズ、スケジュール、リソース、予算に応じて、「UXデザインプロセス」の各段階で使用される技術やツールは異なる場合があります。
                <br />
                また、リソース、時間、予算が限られている場合は、これらの方法の軽量版を使用することもあります。
                <br />
                以下は、一般的に使用される方法の例です：
              </div>
              <div class="ux-tag-hero">
                <a href="#" class="ux-tag-box jp w-inline-block">
                  <div>
                    リサーチ（定量調査/定性調査）
                    <br />
                  </div>
                </a>
                <a href="#" class="ux-tag-box jp red w-inline-block">
                  <div>
                    インタビュー
                    <br />
                  </div>
                </a>
                <a href="#" class="ux-tag-box red w-inline-block">
                  <div>
                    ペルソナ
                    <br />
                  </div>
                </a>
                <a href="#" class="ux-tag-box jp w-inline-block">
                  <div>
                    SWOT/TOWS分析
                    <br />
                  </div>
                </a>
                <a href="#" class="ux-tag-box red w-inline-block">
                  <div>
                    ジャーニーマップ
                    <br />
                  </div>
                </a>
                <a href="#" class="ux-tag-box jp w-inline-block">
                  <div>
                    共感マップ
                    <br />
                  </div>
                </a>
                <a href="#" class="ux-tag-box jp w-inline-block">
                  <div>
                    ストーリーボード
                    <br />
                  </div>
                </a>
                <a href="#" class="ux-tag-box jp w-inline-block">
                  <div>
                    Crazy Eight
                    <br />
                  </div>
                </a>
                <a href="#" class="ux-tag-box jp w-inline-block">
                  <div>
                    マインドマッピング
                    <br />
                  </div>
                </a>
                <a href="#" class="ux-tag-box red w-inline-block">
                  <div>
                    ユーザビリティテスト
                    <br />
                  </div>
                </a>
                <a href="#" class="ux-tag-box jp w-inline-block">
                  <div>
                    ユーザー観察
                    <br />
                  </div>
                </a>
                <a href="#" class="ux-tag-box jp w-inline-block">
                  <div>
                    A/Bテスティング
                    <br />
                  </div>
                </a>
                <a href="#" class="ux-tag-box red w-inline-block">
                  <div>
                    ユーザーフロー
                    <br />
                  </div>
                </a>
                <a href="#" class="ux-tag-box red w-inline-block">
                  <div>
                    UX監査
                    <br />
                  </div>
                </a>
                <a href="#" class="ux-tag-box jp w-inline-block">
                  <div>
                    ブレインストーミング
                    <br />
                  </div>
                </a>
                <a href="#" class="ux-tag-box red w-inline-block">
                  <div>
                    ワイヤフレーム
                    <br />
                  </div>
                </a>
                <a href="#" class="ux-tag-box jp w-inline-block">
                  <div>
                    プロトタイプ
                    <br />
                  </div>
                </a>
                <a href="#" class="ux-tag-box jp w-inline-block">
                  <div>
                    KPI分析
                    <br />
                  </div>
                </a>
                <a href="#" class="ux-tag-box jp w-inline-block">
                  <div>
                    デザインスプリント
                    <br />
                  </div>
                </a>
              </div>
              <div class="tmln-event-content-box"></div>
            </div>
            <div class="tmln-event-3">
              <div class="tmln-title-box english">
                <div class="text-block-231">Project:</div>
                <img src="meditel.svg" loading="lazy" width="126" alt="" />
              </div>
              <div class="tmln-event-content-box">
                <div class="tmln-event-row-box-2">
                  <div class="text-block-230">
                    <span class="tmln-event-subtitle">クライアント: </span>NTT
                    DOCOMO
                  </div>
                </div>
                <div class="tmln-event-row-box-2">
                  <div class="text-block-230">
                    <span class="tmln-event-subtitle">プロジェクト：</span>
                    医療サービス・アプリ、ウェブサービスサイト、管理システム画面、LP、ブランディング、プロモーションデザイン...
                  </div>
                </div>
                <div class="tmln-event-row-box-2">
                  <div class="text-block-230">
                    <span class="tmln-event-subtitle">使用UXメソッド：</span>
                    リサーチ、スティックホルダーインタビュー、ユーザーインタビュー、デザインスプリント、ワイヤフレーム、プロトタイプ、ユーザーテスティング...
                  </div>
                </div>
              </div>
              <div class="tmln-photo-box">
                <img
                  src="meditel-photo-hor.png"
                  loading="lazy"
                  sizes="100vw"
                  srcset="meditel-photo-hor-p-500.png 500w, meditel-photo-hor-p-800.png 800w, meditel-photo-hor-p-1080.png 1080w, meditel-photo-hor.png 1105w"
                  alt=""
                  class="photo-hor"
                />
                <img
                  src="meditel-photo-ver.png"
                  loading="lazy"
                  sizes="100vw"
                  srcset="meditel-photo-ver-p-500.png 500w, meditel-photo-ver.png 555w"
                  alt=""
                  class="photo-ver"
                />
              </div>
            </div>
            <div class="tmln-event-3">
              <div class="tmln-title-box english">
                <div class="text-block-231">Project:</div>
                <img src="penmark.svg" loading="lazy" width="126" alt="" />
              </div>
              <div class="tmln-event-content-box">
                <div class="tmln-event-row-box-2">
                  <div class="text-block-230">
                    <span class="tmln-event-subtitle">クライアント: </span>
                    Penmark株式会社
                  </div>
                </div>
                <div class="tmln-event-row-box-2">
                  <div class="text-block-230">
                    <span class="tmln-event-subtitle">プロジェクト：</span>
                    大学生向けサービス /
                    時間割り、コミュニティ、チャット、シラバス、バイト、部屋探し、プロモーションデザイン...
                  </div>
                </div>
                <div class="tmln-event-row-box-2">
                  <div class="text-block-230">
                    <span class="tmln-event-subtitle">使用UXメソッド：</span>
                    リサーチ、スティックホルダーインタビュー、SWOT/TOWS分析、KPI分析、ユーザーインタビュー、デザインスプリント、ワイヤフレーム、プロトタイプ、ユーザーテスティング...
                  </div>
                </div>
              </div>
              <div class="tmln-photo-box">
                <img
                  src="penmark-photo-hor.png"
                  loading="lazy"
                  sizes="100vw"
                  srcset="penmark-photo-hor-p-500.png 500w, penmark-photo-hor-p-800.png 800w, penmark-photo-hor.png 1105w"
                  alt=""
                  class="photo-hor-penmark"
                />
                <img
                  src="penmark-photo-ver.png"
                  loading="lazy"
                  alt=""
                  class="photo-ver penmark"
                />
              </div>
            </div>
          </div>
          <div id="Design" class="tl-title-box">
            <div class="tmln-event-3">
              <div class="tl-title">
                <div class="text-block-28">
                  UI DESIGN!
                  <br />
                </div>
              </div>
              <div class="def-text">
                16年の経験とデザインへの鋭い感覚を持っており、ビジュアル的に魅力的かつ直感的に使用できるピクセルパーフェクトなインターフェースを作成できます。
                <br />
                <br />
                取り組む技術やツールについて深い理解を持ち、開発者や他のチームメンバーと効果的にコミュニケーションを取ることができます。
                <br />
                スタートアップ企業や大手企業のB2CプロジェクトやB2Bサービスに取り組む場合でも、ユーザーのニーズとビジネスまたは組織の目標をバランス良く考慮し、両方の要件を満たすインターフェースを提供します。
              </div>
            </div>
          </div>
          <div id="Wireframe" class="tl-title-box">
            <div class="tmln-event-3">
              <div class="tl-title">
                <div class="text-block-28">
                  WIREFRAME!
                  <br />
                </div>
              </div>
              <div class="def-text">
                アイデアや仮説を検証するために、ワイヤーフレームを作成するだけでなく、ユーザーに実際に体験してもらうためのインタラクティブなワイヤーフレームも作成します。これにより、有益なフィードバックを得るとともに、アイデアの検証を行うことができます。
                <br />
                <br />
                アイデアやソリューションを可視化し、ステークホルダーが容易に意思決定できるよう、より理解・吸収しやすくするためにワイヤーフレームを作成します。
                <br />
                それらのアイデアやソリューションをユーザーとテストする際には、PrincipleやProtoPieなどのツールを使ったインタラクティブなワイヤーフレームを作成します。
                <br />
                <br />
              </div>
              <div class="workk-box-3 vertical flip">
                <img
                  src="Meditel-wf-02.gif"
                  loading="lazy"
                  width="640"
                  alt=""
                  class="image-225"
                />
                <div class="workk-image-hero meditel">
                  <img
                    src="https://uploads-ssl.webflow.com/62729f3e78ca92be12d3922a/62729f3e78ca9220ead39338_Meditel-wf-01.gif"
                    loading="lazy"
                    alt=""
                    class="image-def smaller"
                  />
                </div>
              </div>
            </div>
            <img
              src="Group-2706.png"
              loading="lazy"
              sizes="(max-width: 479px) 97vw, (max-width: 767px) 98vw, (max-width: 991px) 93vw, 82vw"
              alt=""
              class="_wf-img"
            />
            <img
              src="med-p-2000.png"
              loading="lazy"
              sizes="(max-width: 479px) 97vw, (max-width: 767px) 98vw, (max-width: 991px) 93vw, 82vw"
              alt=""
              class="_wf-img"
            />
          </div>

          <div id="Design" class="tl-title-box">
            <div class="tmln-event-3">
              <div class="tl-title">
                <div class="text-block-28">
                  Prototype!
                  <br />
                </div>
              </div>
              <div class="def-text">
                基本的に、UIデザインを完成させた後にプロトタイプを作成し、開発が始まる前にアプリのユーザーエクスペリエンスと機能をテストして検証します。
                <br />
                プロトタイピングにより、元のUIデザインでは明らかにならなかったデザイン上の問題が明らかになります。
                <br />
                アプリへのユーザーのインタラクションを効果的にシミュレートするために、プロトタイプが開発後の最終のプロダクトと同様に見えるように機能することを確認します。ProtoPie、Principle、SVGアニメーションなどのツールを使用します。
                <br />
                <br />
                これらのツールを使用することで、ユーザーエクスペリエンスを正確に反映したインタラクティブなプロトタイプを作成することができます。
                <br />
                また、異なるシナリオやユーザーフローをテストして、開発が始まる前に潜在的な問題を特定することもできます。
                <br />
                これにより、長期的に時間とリソースを節約することができます。
                <br />
                全体的に、プロトタイピングは、最終のプロダクトがユーザーのニーズや期待に応えることを確認するためのデザインプロセスにおいて不可欠なステップです。
                <br />
                ユーザーはこれを操作して、最終的なプロダクトとまったく同じ体験をすることができます。これにより、プロトタイプから得られる洞察が正確かつ有用であることが保証されます。
                <br />
              </div>
            </div>
          </div>

          <div id="Branding" class="tl-title-box">
            <div class="tmln-event-3">
              <div class="tl-title">
                <div class="text-block-28">
                  BRANDING!
                  <br />
                </div>
              </div>
              <div class="def-text">
                ブランディングとデザインの専門知識を生かし、混雑した市場で際立つブランドを作ることができます。強いブランドは、顧客に共感する独自で記憶に残るイメージを作り出します。
                <br />
                <br />
                ブランド開発とグラフィックデザインの豊富な知識をもとに、クライアントがビジネス目標に合わせて一貫した、魅力的で効果的なブランドアイデンティティを作り上げ、その戦略的なアライメントを実現するお手伝いができます。
                <br />
                <br />
                MVV戦略、ロゴデザイン、命名、カラー、ブランドトーン＆マナー、アイコン、オリジナルイラストなどのブランディングサービスを提供しています。
                <br />
                既存のブランドの再ブランディングや、ニーズに合わせてブランドを新たに構築することもできます。
              </div>
            </div>
            <div class="gallery-box-logo">
              <img
                src="med-logo.png"
                loading="lazy"
                alt=""
                class="girs-card-img-logo"
              />
              <img
                src="Logo13.gif"
                loading="lazy"
                width="400"
                alt=""
                class="girs-card-img-logo"
              />
              <img
                src="alpha-logo.png"
                loading="lazy"
                alt=""
                class="girs-card-img-logo"
              />
              <img
                src="Skyki-logo.png"
                loading="lazy"
                alt=""
                class="girs-card-img-logo"
              />
              <img
                src="mossy-logo.png"
                loading="lazy"
                alt=""
                class="girs-card-img-logo"
              />
              <img
                src="chapa-logo.png"
                loading="lazy"
                alt=""
                class="girs-card-img-logo"
              />
              <img
                src="Pinjob.png"
                loading="lazy"
                alt=""
                class="girs-card-img-logo"
              />
              <img
                src="coloro2.gif"
                loading="lazy"
                width="400"
                alt=""
                class="girs-card-img-logo"
              />
            </div>
          </div>
          <div id="Interaction" class="tl-title-box">
            <div class="tmln-event-3-2">
              <div class="tl-title">
                <div class="text-block-28">Interaction!</div>
              </div>
              <div class="def-text">
                ユーザーの行動について深い理解を持ち、ユーザーに対する強い共感力を持っています。ユーザーのニーズを予測し、直感的で使いやすいインターフェースを設計することができます。
                <br />
                インタラクションやマイクロインタラクション、その他のビジュアル要素を作成するスキルを持っています。
                <br />
                <br />
                これらの要素は、ユーザー体験を向上させることができます。ProtoPie、Principle、Figma、After
                Effects、SVGアニメーションツールなど、さまざまなインタラクションデザインツールに熟練しています。
                <br />
                これにより、チームやステークホルダーと簡単に共有できる高品質なインタラクションやアニメーションを作成することができます。
              </div>
            </div>
            <div class="gallery-box">
              <img
                src="3d-view.gif"
                loading="lazy"
                width="400"
                alt=""
                class="girs-card-img"
              />
              <img
                src="firstshot.gif"
                loading="lazy"
                width="400"
                alt=""
                class="girs-card-img"
              />
              <img
                src="nof-ver2.gif"
                loading="lazy"
                width="400"
                alt=""
                class="girs-card-img"
              />
              <img
                src="bell.gif"
                loading="lazy"
                width="400"
                alt=""
                class="girs-card-img"
              />
              <img
                src="Untitled-2-2.gif"
                loading="lazy"
                width="400"
                alt=""
                class="girs-card-img"
              />
              <img
                src="HMV-002.gif"
                loading="lazy"
                width="529"
                alt=""
                class="girs-card-img"
              />
              <img
                src="Works-interaction_15.gif"
                loading="lazy"
                alt=""
                class="girs-card-img"
              />
              <img
                src="oooooooooooh.gif"
                loading="lazy"
                width="400"
                alt=""
                class="girs-card-img"
              />
              <img
                src="mossy----.gif"
                loading="lazy"
                alt=""
                class="girs-card-img"
              />
              <img
                src="notifications.gif"
                loading="lazy"
                width="400"
                alt=""
                class="girs-card-img"
              />
              <img
                src="Netch-5.gif"
                loading="lazy"
                width="400"
                alt=""
                class="girs-card-img"
              />
            </div>
          </div>
          <div id="Education" class="tl-title-box">
            <div class="tmln-event-3-2">
              <div class="tl-title">
                <div class="text-block-28">Education!</div>
              </div>
              <div class="def-text">
                2013年にUXUIデザイナーのコミュニティを立ち上げ、最新のUXとUIデザインに関する情報、スキル、知識の共有を目指しました。
                <br />
                UXUIデザイナー向けのサーボスも作成し、UUD.ioというウェブサイトでデザイナー向けのリソース、サービス、情報を提供しています。
                <br />
                <br />
                それ以来、多くのUX/UIデザインのイベント、ワークショップ、コースを企画・参加し、またデザイン専門学校での教鞭を執る経験も積みました。
                <br />
                このため、必要なスキル、知識、経験を持ち、異なるバックグラウンドを持つ聴衆と共に小規模なワークショップから大規模なワークショップまで進行することができます。
                <br />
                <br />
                また、教育能力により、すべてのUXデザインの方法、ツール、アクティビティを理解し、クライアントチームに肯定的な影響を与えるために最良の方法で実行できます。
                <br />
              </div>
            </div>
            <div class="tmln-event-3">
              <div class="tmln-title-box english">
                <div class="text-block-231">
                  UUD (UXUI Designer)<span class="text-span">設立</span>
                </div>
                <img src="uud.svg" loading="lazy" width="76" alt="" />
              </div>
              <div class="tmln-event-content-box">
                <div class="tmln-event-row-box-2">
                  <div class="text-block-230">
                    IT業界向け（特にUXUIデザイン業界）
                  </div>
                </div>
                <div class="tmln-event-row-box-2">
                  <div class="text-block-230">
                    世界のデザインの情報を提供・共有、UXUIデザイン知識
                  </div>
                </div>
                <div class="tmln-event-row-box-2">
                  <div class="text-block-230">
                    <span class="tmln-event-subtitle">活動：</span>
                    イベント、ワークショップ、デザイン講座、交流会...
                  </div>
                </div>
                <div class="tmln-event-row-box-2">
                  <div class="text-block-230">法人向け研修（UXUIデザイン）</div>
                </div>
              </div>
              <div class="tmln-photo-box">
                <img
                  src="uud-photo-hor.png"
                  loading="lazy"
                  sizes="(max-width: 991px) 100vw, 78vw"
                  srcset="uud-photo-hor-p-500.png 500w, uud-photo-hor-p-800.png 800w, uud-photo-hor.png 1075w"
                  alt=""
                  class="photo-hor"
                />
                <img
                  src="uud-photo-ver.png"
                  loading="lazy"
                  sizes="(max-width: 479px) 97vw, (max-width: 767px) 98vw, (max-width: 932px) 86vw, (max-width: 991px) 802px, 100vw"
                  srcset="uud-photo-ver-p-500.png 500w, uud-photo-ver.png 802w"
                  alt=""
                  class="photo-ver"
                />
              </div>
            </div>
          </div>
          <section id="Tools" class="tl-title-box wf-section">
            <div class="tmln-event-3-2">
              <div class="tl-title">
                <div class="text-block-28">Tools!</div>
              </div>
              <div class="def-text">
                業界の最新トレンドと進歩に情熱を持ち、デザインワークフローを改善するために常に新しいツールやソフトウェアを学ぶことを熱望しています。
                <br />
                様々なツールを使うことで、より多目的なデザイナーになるだけでなく、それぞれのツールの利点と欠点をより深く理解できると信じています。
                <br />
                <br />
                UIデザインにはFigmaやSketchを、アニメーションやインタラクションデザインにはProtopieやPrinciple、After
                Effectsなど幅広いツールを使うことで、より魅力的なデザインを生み出すことができます。
                <br />
                また、マイクロインタラクションにはRiveを使うこともあります。これらのツールは、魅力的な体験を作り上げるために非常に強力だと感じています。
                <br />
                <br />
                日々のルーティンの管理にはユーティリティを活用し、タスクを効率的に管理することで、より密接なクライアントとのコミュニケーションやコラボレーションが可能になります。
                <br />
                また、仕事に楽しさや創造性を加える小さなユーティリティを使うことも好きです。このアプローチは効率を高めるだけでなく、プロセスを楽しくするのに大いに役立ちます。
                <br />
                <br />
              </div>
              <div class="tl-table-hiro longer">
                <div class="tmln-event-row-box-2 tools">
                  <img
                    src="logo-tools13.svg"
                    loading="lazy"
                    width="48"
                    height="48"
                    alt="Figma"
                    class="iconz"
                  />
                  <img
                    src="logo-tools14.svg"
                    loading="lazy"
                    width="48"
                    height="48"
                    alt="Sketch"
                    class="iconz"
                  />
                  <img
                    src="logo-tools01.svg"
                    loading="lazy"
                    width="48"
                    height="39"
                    alt="ProtoPie"
                  />
                  <img
                    src="logo-tools02.svg"
                    loading="lazy"
                    width="48"
                    height="46"
                    alt="Principle"
                    class="iconz"
                  />
                  <img
                    src="logo-tools08.svg"
                    loading="lazy"
                    width="41"
                    height="48"
                    alt="Notion"
                    class="iconz"
                  />
                  <img
                    src="logo-tools04.svg"
                    loading="lazy"
                    width="42"
                    height="48"
                    alt="Slack"
                    class="iconz"
                  />
                  <img
                    src="05.svg"
                    loading="lazy"
                    width="39"
                    height="48"
                    alt="Photoshop"
                    class="iconz"
                  />{" "}
                  <img
                    src="logo-tools10.svg"
                    loading="lazy"
                    width="39"
                    height="48"
                    alt="After Effects"
                    class="iconz"
                  />
                  <img
                    src="logo-tools09.svg"
                    loading="lazy"
                    width="39"
                    height="48"
                    alt="Illustrator"
                    class="iconz"
                  />
                  <img
                    src="logo-tools11.svg"
                    loading="lazy"
                    width="39"
                    height="48"
                    alt="Photoshop"
                    class="iconz"
                  />
                  <img
                    src="01.svg"
                    loading="lazy"
                    width="39"
                    height="48"
                    alt="Photoshop"
                    class="iconz"
                  />
                  <img
                    src="02.svg"
                    loading="lazy"
                    width="39"
                    height="48"
                    alt="Photoshop"
                    class="iconz"
                  />
                  <img
                    src="03.svg"
                    loading="lazy"
                    width="39"
                    height="48"
                    alt="Photoshop"
                    class="iconz"
                  />{" "}
                  <img
                    src="04.svg"
                    loading="lazy"
                    width="39"
                    height="48"
                    alt="Photoshop"
                    class="iconz"
                  />{" "}
                  <img
                    src="06.svg"
                    loading="lazy"
                    width="39"
                    height="48"
                    alt="Photoshop"
                    class="iconz"
                  />{" "}
                  <img
                    src="07.svg"
                    loading="lazy"
                    width="39"
                    height="48"
                    alt="Photoshop"
                    class="iconz"
                  />{" "}
                  <img
                    src="08.svg"
                    loading="lazy"
                    width="39"
                    height="48"
                    alt="Photoshop"
                    class="iconz"
                  />
                </div>
                <div class="tmln-note-row-box no-left-p">
                  <div class="red-2">＊</div>
                  <div class="text-block-21 dark">
                    上記は、日々のUXUIデザインのワークフローで使用している主なツールです。
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="Tools" class="tl-title-box footer wf-section">
            <div class="footer-slogan">IT&#x27;S ALL ABOUT USERS!</div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Hafid;
