import React from 'react';
import PropTypes from 'prop-types';
import "../../styles/main.scss";
import "../../styles/designSys.scss";
import HoverIcon from "../atom/Icon";

function SectionCard({ icon, title, content, className, children }) {
  return (
    <div className={`section-top-hero ${className}`}>
      <div className="section-text-box">
        <span>{title}</span>
        <div className="text_box" dangerouslySetInnerHTML={{ __html: content }} />
       
      </div>
      {children}
      <div className="section-illus-box">
        <CustomIcon icon={icon} />
      </div>
    </div>
  );
}

SectionCard.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  className: PropTypes.string, // Additional classNames for customization
  children: PropTypes.node // Child component(s) to be rendered
};

function CustomIcon({ icon }) {
  return <HoverIcon icon={icon} />;
}

export default SectionCard;
