// BtnDef.js
import React, { useState } from "react";
import "../../styles/main.scss";
import "../../styles/designSys.scss";
import HoverIcon from "../atom/Icon";
import BtnDef from "../atom/Btn";
import Button from "../atom/Button";
import CustomIcon from "../../data/IconCompo";

export default function Footer() {
  const [modalOpen, setModalOpen] = useState(false);

  const handleNavClick = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <div className="footer-hero">
        <div className="footer-logo_box">
          <BtnDef className="logo_button">
            <HoverIcon
              className="btn_sec"
              icon="juusando_logo"
              iconHover="juusando_logo"
            />
            <span>JUUSANDO!</span>
          </BtnDef>
        </div>

        <div className="footer-hero horizontal">
          <span>© 2024 JUUSANDO!</span>
          <div className="footer-nav_box2">
            <nav onClick={handleNavClick}>会社概要</nav>
            {/* <nav>Terms of Use</nav> */}
          </div>
        </div>
      </div>
      {modalOpen && (
        <div className="kaisha_lightbox">
          <div className="kaisha-box">
            <span className="close">
              <Button
                icon1="close"
                icon2="close"
                className={"btn_c_icn"}
                onClick={closeModal}
              />
            </span>

            <h2>会社概要</h2>
            <div className="company-names">
              <div className="company">
                <h8>会社名:</h8>
                <h9>ZILMOO株式会社</h9>
              </div>
              <div className="company">
                <h8>ブランド名:</h8>
                <h9>JUUSANDO!</h9>
              </div>
              <div className="company">
                <h8>設立:</h8>
                <h9>2013年01月13日</h9>
              </div>
              <div className="company">
                <h8>所在地:</h8>
                <h9>東京都渋谷区道玄坂1-16-6 二葉ビル 8B</h9>
              </div>
              <div className="company">
                <h8>CEO:</h8>
                <h9>はふぃど・ぶるひむ</h9>
              </div>
            </div>
            {/* <button className="close-button" onClick={closeModal}>Close</button> */}
          </div>
        </div>
      )}
    </>
  );
}
