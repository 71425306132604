// Header.js
import React, { useEffect, useRef, useState } from "react";
// import "../../styles/main.scss";
// import "../../styles/designSys.scss";
import HoverIcon from "../atom/Icon";
import BtnDef from "../atom/Btn";
import { useLocation } from "react-router-dom";
import CustomIcon from "../../data/IconCompo";
import MobileMenu from "./MobileMenu";

export default function Header({
  className,
  setScrollpos,
  setMobileMenu,
  mobileMenu,
}) {
  const head = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const isDesktopLarge = window.innerWidth > 920;
      const isDesktop = window.innerWidth > 540;
      const transient =
        (window.innerHeight - window.scrollY) / window.innerHeight;

      let initialHeight, finalHeight;
      if (isDesktopLarge) {
        initialHeight = 120;
        finalHeight = 80;
      } else if (isDesktop) {
        initialHeight = 88;
        finalHeight = 64;
      } else {
        initialHeight = 68;
        finalHeight = 54;
      }

      const heightDifference = initialHeight - finalHeight;
      const minHeight = finalHeight;
      const height = `${Math.max(
        minHeight,
        initialHeight - (1 - transient) * heightDifference
      )}px`;

      const boxShadowX = Math.min(2, Math.max(0, 2 - transient * 2));
      const boxShadowY = Math.min(2, Math.max(0, 3 - transient * 3));
      const backgroundColorAlpha = Math.max(0, 1 - transient);
      head.current.style.height = height;
      head.current.style.boxShadow = `0px 0px ${boxShadowX}px ${boxShadowY}px #00000012`;
      head.current.style.backgroundColor = `rgba(255, 255, 255, ${backgroundColorAlpha})`;
    };

    const handleResize = () => {
      handleScroll(); // Recalculate initialHeight on resize
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize); // Add resize event listener

    // Clean up event listeners
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const location = useLocation();

  return (
    <>
      <div className={`header-hero ${className}`} ref={head}>
        <div className="header-logo_box">
          <div
            className="logo_button logo_button_cont"
            onClick={() => {
              if (
                window.location.href.split("/")[3] !== "home" &&
                window.location.href.split("/")[3] !== ""
              ) {
                window.location = "/";
              } else {
                setTimeout(() => {
                  document.querySelector("html").scrollTo({
                    top: 0,
                    left: 0, // If you want to scroll horizontally, you can set a value here
                    behavior: "smooth", // Use smooth scrolling behavior
                  });
                }, 200);
              }
            }}
          >
            <HoverIcon
              className="btn_sec"
              icon="juusando_logo"
              iconHover="logo_ani"
            />
            <span>JUUSANDO!</span>
          </div>
        </div>

        <div className="header-nav_box">
          <nav
            className="header-nav"
            onClick={() => {
              if (
                window.location.href.split("/")[3] !== "home" &&
                window.location.href.split("/")[3] !== ""
              ) {
                window.location = "home/about";
              } else {
                setTimeout(() => {
                  document.querySelector("html").scrollTo({
                    top: document.querySelector(".about-section").offsetTop,
                    left: 0, // If you want to scroll horizontally, you can set a value here
                    behavior: "smooth", // Use smooth scrolling behavior
                  });
                }, 200);
              }
            }}
          >
            ABOUT
          </nav>
          <nav
            className="header-nav"
            onClick={() => {
              if (
                window.location.href.split("/")[3] !== "home" &&
                window.location.href.split("/")[3] !== ""
              ) {
                window.location = "home/service";
              } else {
                setTimeout(() => {
                  document.querySelector("html").scrollTo({
                    top: document.querySelector(".service-section").offsetTop,
                    left: 0, // If you want to scroll horizontally, you can set a value here
                    behavior: "smooth", // Use smooth scrolling behavior
                  });
                }, 200);
              }
            }}
          >
            SERVICE
          </nav>
          {/* <nav
            className="header-nav "
            onClick={() => {
              if (
                window.location.href.split("/")[3] !== "home" &&
                window.location.href.split("/")[3] !== ""
              ) {
                window.location = "home/style";
              } else {
                setTimeout(() => {
                  document.querySelector("html").scrollTo({
                    top: document.querySelector(".style-section").offsetTop,
                    left: 0, // If you want to scroll horizontally, you can set a value here
                    behavior: "smooth", // Use smooth scrolling behavior
                  });
                }, 200);
              }
            }}
          >
            STYLE
          </nav> */}
          <nav
            className="header-nav "
            onClick={() => {
              if (
                window.location.href.split("/")[3] !== "home" &&
                window.location.href.split("/")[3] !== ""
              ) {
                window.location = "home/process";
              } else {
                setTimeout(() => {
                  document.querySelector("html").scrollTo({
                    top: document.querySelector(".process-section").offsetTop,
                    left: 0, // If you want to scroll horizontally, you can set a value here
                    behavior: "smooth", // Use smooth scrolling behavior
                  });
                }, 200);
              }
            }}
          >
            PROCESS
          </nav>
          <nav
            className="header-nav"
            onClick={() => {
              if (
                window.location.href.split("/")[3] !== "home" &&
                window.location.href.split("/")[3] !== ""
              ) {
                window.location = "home/works";
              } else {
                setTimeout(() => {
                  document.querySelector("html").scrollTo({
                    top: document.querySelector(".works-section").offsetTop,
                    left: 0, // If you want to scroll horizontally, you can set a value here
                    behavior: "smooth", // Use smooth scrolling behavior
                  });
                }, 200);
              }
            }}
          >
            WORKS
          </nav>
        </div>

        <div className="header-right_box">
          <div
            className="btn_sec contact-btn"
            onClick={() => {
              if (
                window.location.href.split("/")[3] !== "home" &&
                window.location.href.split("/")[3] !== ""
              ) {
                window.location = "home/contact";
              } else {
                setTimeout(() => {
                  document.querySelector("html").scrollTo({
                    top:
                      document.querySelector(".contact-section").offsetTop - 50,
                    left: 0, // If you want to scroll horizontally, you can set a value here
                    behavior: "smooth", // Use smooth scrolling behavior
                  });
                }, 200);
              }
            }}
          >
            <h5>CONTACT</h5>
            <HoverIcon className="" icon="mail" iconHover="pinjob_ani" />
          </div>
        </div>

        <div className="header-hum_box">
          <BtnDef
            className="btn_sec"
            onClick={() => {
              setScrollpos(document.querySelector("html").scrollTop);
              mobileMenu ? setMobileMenu(false) : setMobileMenu(true);
            }}
          >
            <HoverIcon className="" icon="humb" iconHover="humb" />
          </BtnDef>
        </div>
      </div>
    </>
  );
}
