// import CustomIcon from "../data/IconCompo";
import "../styles/main.scss";
import "../styles/designSys.scss";
import React, { useState } from "react";
import InputSelectData from "../ui/atom/InputSelectData";
import Header from "../ui/comp/Header";
import SectionCard from "../ui/comp/SectionCard";
import TopSection from "../ui/comp/TopSection";
import SubSectionCard from "../ui/comp/SubSectionCard";
import SubCard from "../ui/comp/SubCard";
import WorkCard from "../ui/comp/WorkCard";
import SloganCrad from "../ui/comp/SloganCard";
import WorkStyleCard from "../ui/comp/WorkStyleCard";
import Footer from "../ui/comp/Footer";
import TeamCard from "../ui/comp/TeamCard";
import WorkTemp from "../ui/comp/WorkTemp";
import WorkTitle from "../ui/atom/works/workTitle";
import WorkDetailCard from "../ui/atom/works/WorkDetailCard";

function Home({ setMobileMenu, mobileMenu, scrollpos, setScrollpos }) {
  return (
    <div className="work-details-hero penmark-details-hero">
      {/* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: HEADER :::::::: */}
      <Header
        className="header-light"
        setMobileMenu={(res) => setMobileMenu(res)}
        mobileMenu={mobileMenu}
        scrollpos={scrollpos}
        setScrollpos={(res) => setScrollpos(res)}
      />
      {/* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: WORK DETAILS :::::::: */}

      <WorkTitle
        // icon="meditel_logo"
        title="Penmark"
        category="Education"
        content="
        <p>ペンマークは大学生専用の履修管理SNSアプリです。<br/>
        大学生活に必要な時間割管理から学内コミュニケーションまで、公式シラバスを元にした時間割作成やトークルーム機能など、情報収集を容易にし、より充実した大学生活を実現します。
        </p>
        "
        image="penmark_00@2x.png"
        tags={["Strategy", "UX Design", "UI Design"]}
        tags2={["Web", "App", "Creative Direction", "Graphic Design"]}
        tags3={[
          "Interview",
          "Research",
          "Persona",
          "Information Achitecture",
          "Journey Map",
          "Design Sprint",
          "Wire-frame",
          "Prototype",
          "Usability Test",
        ]}
      />
      <WorkDetailCard
        // className={"text-only"}
        title="The Challenge"
        content="<p>
        Penmarkプロジェクトでの主な課題の1つは、大学生向けに特別に設計された時間割管理とソーシャルネットワーキング機能をシームレスに統合するユーザーエクスペリエンスをデザインすることでした。<br/>
        授業のスケジューリングの複雑さと直感的なコミュニケーションツールの必要性のバランスをとることは、重要なUX/UIの課題でした。              
        </p>"
        title2="The Solution"
        content2="<p>
        この課題に対処するために、私はユーザー中心のデザインアプローチを採用し、時間割の作成プロセスを簡素化し、ソーシャルインタラクション機能を強化することに焦点を当てました。<br/>
        徹底的なユーザー調査を実施し、情報アーキテクチャの原則を活用して、使いやすい時間割作成インターフェースと直感的なコミュニケーションチャネルを設計しました。<br/>
        また、視覚的な階層技術と明確なナビゲーションパターンを活用して、ユーザーがアプリのさまざまな機能を簡単に操作できるようにし、全体的な使いやすさとユーザー満足度を向上させました。              
        </p>"
      />

      <WorkDetailCard
        className="color-01"
        category="Discovery & Define"
        title="User Interview & Design Sprint"
        content="<p>
        ペルソナはユーザーのニーズを具体化し、デザイン上の意思決定を補助し、ユーザーインタビューの結果から得られる洞察は最適なソリューションを提供するための基盤となります。<br/>
        HMW質問は問題解決に向けたアイデア発想を促進します。<br/>
        これらの画像はデザインプロセスの重要な部分を示し、プロジェクトの成功に向けた取り組みを支援します。              
        </p>"
        image="penmark_persona@2x.png"
      />
      <WorkDetailCard
        //  icon="illus_01"
        image2="test5.png"
        category="Design"
        title="UI Design"
        content="<p>
        ペンマークプロジェクトのUIデザインにおいて、初めに、オンボーディング画面では、ユーザーがアプリの基本的な機能や利点を理解しやすくするために、シンプルなインターフェースとわかりやすい案内が設計されました。<br/>
        時間割と授業管理セクションでは、直感的な操作性と視覚的な整合性が重視され、ユーザーがスムーズに時間割を作成し、授業情報を管理できるようになりました。<br/><br/>
        さらに、コミュニティセクションでは、同じ大学の仲間と簡単に交流できるよう、使いやすいチャット機能や掲示板がデザインされました。<br/>
        これらのUIデザインは、ユーザーのニーズに合った使いやすいアプリケーションを提供するために、デザインプロセス全体でユーザー中心のアプローチが取られました。              
        </p>"
        image="penmark_ui@2x.png"
      />
      <WorkDetailCard
        className="color-01"
        image2="test5.png"
        category="Design"
        title="UI Design Challenge"
        content="<p>
        ペンマークプロジェクトの時間割カレンダーの3つのページを示しています。特に、7日間のビューの設計において、ユーザーが多くのデータを小さなスペースに追加できるようにするというUIデザインの課題に直面しました。<br/>
        この課題に対処するために、私は情報の重要性に基づいて視覚的な優先順位を設定し、日付や時間の重要な情報を強調するためにカラーリングやアイコンを活用しました。<br/><br/>
        また、コンパクトなレイアウトとスクロール可能な領域を提供することで、ユーザーが大量のデータを効率的に表示および管理できるようにしました。<br/>
        これにより、ユーザーが簡単に時間割を作成し、スケジュールを把握できるようになり、使いやすいアプリケーションを提供することができました。             
         </p>"
        image="penmark_ui2@2x.png"
      />
      <WorkDetailCard
        className="color-03"
        category="Define"
        title="Wireframe"
        content="<p>
        ワイヤフレーム画像は、ペンマークプロジェクトにおけるUXUIデザイナーの役割を示しています。これらのワイヤフレームは、アプリの各機能や画面のレイアウトを詳細にプランニングし、チームと共有するために使用されました。<br/>
        ワイヤフレームの作成には、ユーザビリティ理論の検証、ユーザーテスト、新しいアイデアのブレインストーミングなどの方法が使用されました。<br/>
        これにより、アプリの機能やユーザーエクスペリエンスの改善に役立つフィードバックを得ることができ、最終的なUIデザインのプロトタイプに向けた効果的な準備が行われました              
        </p>"
        image="penmark_wf@2x.png"
      />

      {/* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: HEADER :::::::: */}
      <Footer />
    </div>
  );
}

export default Home;
