
import React from 'react';

import { ReactComponent as Process_define } from '../assets/icons/Process_define.svg';
import { ReactComponent as Process_deliver } from '../assets/icons/Process_deliver.svg';
import { ReactComponent as Process_design } from '../assets/icons/Process_design.svg';
import { ReactComponent as Process_discover } from '../assets/icons/Process_discover.svg';
import { ReactComponent as Process_top } from '../assets/icons/Process_top.svg';
import { ReactComponent as add } from '../assets/icons/add.svg';
import { ReactComponent as back } from '../assets/icons/back.svg';
import { ReactComponent as bold } from '../assets/icons/bold.svg';
import { ReactComponent as btn_bg } from '../assets/icons/btn_bg.svg';
import { ReactComponent as btn_bg_hover } from '../assets/icons/btn_bg_hover.svg';
import { ReactComponent as cal } from '../assets/icons/cal.svg';
import { ReactComponent as cart } from '../assets/icons/cart.svg';
import { ReactComponent as check_off } from '../assets/icons/check_off.svg';
import { ReactComponent as check_on } from '../assets/icons/check_on.svg';
import { ReactComponent as clear } from '../assets/icons/clear.svg';
import { ReactComponent as clear_ani } from '../assets/icons/clear_ani.svg';
import { ReactComponent as clicks } from '../assets/icons/clicks.svg';
import { ReactComponent as close } from '../assets/icons/close.svg';
import { ReactComponent as company } from '../assets/icons/company.svg';
import { ReactComponent as company_ani } from '../assets/icons/company_ani.svg';
import { ReactComponent as contact_top } from '../assets/icons/contact_top.svg';
import { ReactComponent as designer } from '../assets/icons/designer.svg';
import { ReactComponent as designer_ani } from '../assets/icons/designer_ani.svg';
import { ReactComponent as dots_on } from '../assets/icons/dots_on.svg';
import { ReactComponent as drop } from '../assets/icons/drop.svg';
import { ReactComponent as drop_down } from '../assets/icons/drop_down.svg';
import { ReactComponent as drop_up } from '../assets/icons/drop_up.svg';
import { ReactComponent as edit } from '../assets/icons/edit.svg';
import { ReactComponent as edit_ani } from '../assets/icons/edit_ani.svg';
import { ReactComponent as employer } from '../assets/icons/employer.svg';
import { ReactComponent as employer_ani } from '../assets/icons/employer_ani.svg';
import { ReactComponent as empty } from '../assets/icons/empty.svg';
import { ReactComponent as empty_on } from '../assets/icons/empty_on.svg';
import { ReactComponent as error } from '../assets/icons/error.svg';
import { ReactComponent as eye_off } from '../assets/icons/eye_off.svg';
import { ReactComponent as eye_on } from '../assets/icons/eye_on.svg';
import { ReactComponent as facebook_line } from '../assets/icons/facebook_line.svg';
import { ReactComponent as hand2 } from '../assets/icons/hand2.svg';
import { ReactComponent as hand4 } from '../assets/icons/hand4.svg';
import { ReactComponent as home } from '../assets/icons/home.svg';
import { ReactComponent as humb } from '../assets/icons/humb.svg';
import { ReactComponent as illus_00 } from '../assets/icons/illus_00.svg';
import { ReactComponent as illus_01 } from '../assets/icons/illus_01.svg';
import { ReactComponent as illus_03 } from '../assets/icons/illus_03.svg';
import { ReactComponent as illus_04 } from '../assets/icons/illus_04.svg';
import { ReactComponent as illus_05 } from '../assets/icons/illus_05.svg';
import { ReactComponent as illus_08 } from '../assets/icons/illus_08.svg';
import { ReactComponent as illus_09 } from '../assets/icons/illus_09.svg';
import { ReactComponent as illus_about } from '../assets/icons/illus_about.svg';
import { ReactComponent as illus_login_user } from '../assets/icons/illus_login_user.svg';
import { ReactComponent as illus_pricing } from '../assets/icons/illus_pricing.svg';
import { ReactComponent as illus_process } from '../assets/icons/illus_process.svg';
import { ReactComponent as illus_wirks } from '../assets/icons/illus_wirks.svg';
import { ReactComponent as illus_works } from '../assets/icons/illus_works.svg';
import { ReactComponent as image } from '../assets/icons/image.svg';
import { ReactComponent as instagram_line } from '../assets/icons/instagram_line.svg';
import { ReactComponent as italic } from '../assets/icons/italic.svg';
import { ReactComponent as jobs } from '../assets/icons/jobs.svg';
import { ReactComponent as jobs_ani } from '../assets/icons/jobs_ani.svg';
import { ReactComponent as juusando_logo } from '../assets/icons/juusando_logo.svg';
import { ReactComponent as left_arrow } from '../assets/icons/left_arrow.svg';
import { ReactComponent as link } from '../assets/icons/link.svg';
import { ReactComponent as linkedin_line } from '../assets/icons/linkedin_line.svg';
import { ReactComponent as list } from '../assets/icons/list.svg';
import { ReactComponent as list_number } from '../assets/icons/list_number.svg';
import { ReactComponent as login } from '../assets/icons/login.svg';
import { ReactComponent as logo_ani } from '../assets/icons/logo_ani.svg';
import { ReactComponent as logo_mark } from '../assets/icons/logo_mark.svg';
import { ReactComponent as logo_type } from '../assets/icons/logo_type.svg';
import { ReactComponent as logout } from '../assets/icons/logout.svg';
import { ReactComponent as mail } from '../assets/icons/mail.svg';
import { ReactComponent as mail_ani } from '../assets/icons/mail_ani.svg';
import { ReactComponent as match } from '../assets/icons/match.svg';
import { ReactComponent as meditel_logo } from '../assets/icons/meditel_logo.svg';
import { ReactComponent as money } from '../assets/icons/money.svg';
import { ReactComponent as money_ani } from '../assets/icons/money_ani.svg';
import { ReactComponent as pin_ani } from '../assets/icons/pin_ani.svg';
import { ReactComponent as pin_in } from '../assets/icons/pin_in.svg';
import { ReactComponent as pin_in_ani } from '../assets/icons/pin_in_ani.svg';
import { ReactComponent as pin_off } from '../assets/icons/pin_off.svg';
import { ReactComponent as pinjob } from '../assets/icons/pinjob.svg';
import { ReactComponent as pinjob_ani } from '../assets/icons/pinjob_ani.svg';
import { ReactComponent as radio_off } from '../assets/icons/radio_off.svg';
import { ReactComponent as radio_on } from '../assets/icons/radio_on.svg';
import { ReactComponent as right_arrow } from '../assets/icons/right_arrow.svg';
import { ReactComponent as right_arrow_ani } from '../assets/icons/right_arrow_ani.svg';
import { ReactComponent as save } from '../assets/icons/save.svg';
import { ReactComponent as search } from '../assets/icons/search.svg';
import { ReactComponent as search_ani } from '../assets/icons/search_ani.svg';
import { ReactComponent as send } from '../assets/icons/send.svg';
import { ReactComponent as send_ani } from '../assets/icons/send_ani.svg';
import { ReactComponent as service_consulting } from '../assets/icons/service_consulting.svg';
import { ReactComponent as service_design } from '../assets/icons/service_design.svg';
import { ReactComponent as service_top } from '../assets/icons/service_top.svg';
import { ReactComponent as service_ux } from '../assets/icons/service_ux.svg';
import { ReactComponent as setting } from '../assets/icons/setting.svg';
import { ReactComponent as signup } from '../assets/icons/signup.svg';
import { ReactComponent as style_com } from '../assets/icons/style_com.svg';
import { ReactComponent as style_free } from '../assets/icons/style_free.svg';
import { ReactComponent as style_top } from '../assets/icons/style_top.svg';
import { ReactComponent as tag } from '../assets/icons/tag.svg';
import { ReactComponent as team_01 } from '../assets/icons/team_01.svg';
import { ReactComponent as team_02 } from '../assets/icons/team_02.svg';
import { ReactComponent as team_03 } from '../assets/icons/team_03.svg';
import { ReactComponent as team_04 } from '../assets/icons/team_04.svg';
import { ReactComponent as team_05 } from '../assets/icons/team_05.svg';
import { ReactComponent as text_big } from '../assets/icons/text_big.svg';
import { ReactComponent as text_small } from '../assets/icons/text_small.svg';
import { ReactComponent as timer } from '../assets/icons/timer.svg';
import { ReactComponent as top_bg } from '../assets/icons/top_bg.svg';
import { ReactComponent as trash } from '../assets/icons/trash.svg';
import { ReactComponent as trash_ani } from '../assets/icons/trash_ani.svg';
import { ReactComponent as twitter } from '../assets/icons/twitter.svg';
import { ReactComponent as twitter_line } from '../assets/icons/twitter_line.svg';
import { ReactComponent as view } from '../assets/icons/view.svg';
import { ReactComponent as views } from '../assets/icons/views.svg';
import { ReactComponent as work_photo_01 } from '../assets/icons/work_photo_01.svg';
import { ReactComponent as work_top } from '../assets/icons/work_top.svg';

const iconComponents = {
  'Process_define': Process_define,
  'Process_deliver': Process_deliver,
  'Process_design': Process_design,
  'Process_discover': Process_discover,
  'Process_top': Process_top,
  'add': add,
  'back': back,
  'bold': bold,
  'btn_bg': btn_bg,
  'btn_bg_hover': btn_bg_hover,
  'cal': cal,
  'cart': cart,
  'check_off': check_off,
  'check_on': check_on,
  'clear': clear,
  'clear_ani': clear_ani,
  'clicks': clicks,
  'close': close,
  'company': company,
  'company_ani': company_ani,
  'contact_top': contact_top,
  'designer': designer,
  'designer_ani': designer_ani,
  'dots_on': dots_on,
  'drop': drop,
  'drop_down': drop_down,
  'drop_up': drop_up,
  'edit': edit,
  'edit_ani': edit_ani,
  'employer': employer,
  'employer_ani': employer_ani,
  'empty': empty,
  'empty_on': empty_on,
  'error': error,
  'eye_off': eye_off,
  'eye_on': eye_on,
  'facebook_line': facebook_line,
  'hand2': hand2,
  'hand4': hand4,
  'home': home,
  'humb': humb,
  'illus_00': illus_00,
  'illus_01': illus_01,
  'illus_03': illus_03,
  'illus_04': illus_04,
  'illus_05': illus_05,
  'illus_08': illus_08,
  'illus_09': illus_09,
  'illus_about': illus_about,
  'illus_login_user': illus_login_user,
  'illus_pricing': illus_pricing,
  'illus_process': illus_process,
  'illus_wirks': illus_wirks,
  'illus_works': illus_works,
  'image': image,
  'instagram_line': instagram_line,
  'italic': italic,
  'jobs': jobs,
  'jobs_ani': jobs_ani,
  'juusando_logo': juusando_logo,
  'left_arrow': left_arrow,
  'link': link,
  'linkedin_line': linkedin_line,
  'list': list,
  'list_number': list_number,
  'login': login,
  'logo_ani': logo_ani,
  'logo_mark': logo_mark,
  'logo_type': logo_type,
  'logout': logout,
  'mail': mail,
  'mail_ani': mail_ani,
  'match': match,
  'meditel_logo': meditel_logo,
  'money': money,
  'money_ani': money_ani,
  'pin_ani': pin_ani,
  'pin_in': pin_in,
  'pin_in_ani': pin_in_ani,
  'pin_off': pin_off,
  'pinjob': pinjob,
  'pinjob_ani': pinjob_ani,
  'radio_off': radio_off,
  'radio_on': radio_on,
  'right_arrow': right_arrow,
  'right_arrow_ani': right_arrow_ani,
  'save': save,
  'search': search,
  'search_ani': search_ani,
  'send': send,
  'send_ani': send_ani,
  'service_consulting': service_consulting,
  'service_design': service_design,
  'service_top': service_top,
  'service_ux': service_ux,
  'setting': setting,
  'signup': signup,
  'style_com': style_com,
  'style_free': style_free,
  'style_top': style_top,
  'tag': tag,
  'team_01': team_01,
  'team_02': team_02,
  'team_03': team_03,
  'team_04': team_04,
  'team_05': team_05,
  'text_big': text_big,
  'text_small': text_small,
  'timer': timer,
  'top_bg': top_bg,
  'trash': trash,
  'trash_ani': trash_ani,
  'twitter': twitter,
  'twitter_line': twitter_line,
  'view': view,
  'views': views,
  'work_photo_01': work_photo_01,
  'work_top': work_top,
};

const CustomIcon = ({ iconName, ...props }) => {
  const SelectedIcon = iconComponents[iconName];
  if (!SelectedIcon) {
    console.warn(`Icon "${iconName}" not found`);
    return null; // Or a default icon
  }

  return <SelectedIcon {...props} />;
};

export default CustomIcon;
