import React from "react";
import CustomIcon from "../../data/IconCompo";
import Button from "../atom/Button";

const PopupTemp = ({
  showTitle,
  showImage,
  showText,
  showButtons,
  showButton1,
  showButton2,
  title,
  text,
  onClose,
  className,
}) => {
  return (
    <div className="popup_lightbox">
      <div className="popup_box">
        <div className="popup_close_box">
          <Button
            icon1="close"
            icon2="close"
            className={"btn_c_icn"}
            onClick={() => {
              onClose();
            }}
          />
        </div>

        {showTitle && <div className="popup_title_box">{title}</div>}
        <div className="popup_content_box_temp">
          {showImage && (
            <div>
              <CustomIcon iconName="illus_09" className="illus" />
            </div>
          )}

          {showText && <p>{text}</p>}

          {showButtons && (
            <div className="buttons_box">
              {showButton1 && (
                <Button text="Send" icon1="send" icon2="send_ani" />
              )}
              {showButton2 && (
                <Button
                  text="Send"
                  icon1="send"
                  icon2="send_ani"
                  className="btn_c_line"
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PopupTemp;
