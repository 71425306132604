import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import "../../styles/main.scss";
import CustomIcon from "../../data/IconCompo";

const Input = ({
  focusedIconName,
  defaultIconName,
  placeholder,
  className,
  label,
  onChange,
  value,
  error,
  innerClass,
  maxLength,
  clearIcon,
  counter,
  pretext,
  suftext,
  customIcon,
  disable,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const input = useRef(null);
  useEffect(() => {
    if (disable) {
      input.current.disabled = true;
    } else {
      input.current.disabled = false;
    }
  }, [disable]);

  return (
    <div
      className={`input-container ${className} ${isFocused ? "focused" : ""} `}
    >
      {label && <label className="label_box">{label}</label>}
      <div
        className={`input_box ${innerClass} ${error && "input_error"} ${
          isFocused && "input_focus"
        } ${disable ? "input-disabled" : ""}`}
      >
        {pretext && <p className="input-pretext">{pretext}</p>}
        <input
          type="text"
          placeholder={placeholder}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onChange={(e) => onChange(e.target.value)}
          value={value ? value : ""}
          className={` ${innerClass} ${pretext && "input-box-pretext"}`}
          ref={input}
          maxLength={maxLength}
        />
        {suftext && <p className="input-pretext">{suftext}</p>}

        {!customIcon ? (
          clearIcon && value ? (
            <CustomIcon
              iconName={value ? "clear" : defaultIconName}
              className={`input-icon ${isFocused ? "focused-icon" : ""}
            ${value ? "input-value-icon" : ""}`}
              onClick={() => {
                onChange("");
              }}
            />
          ) : (
            <CustomIcon
              iconName={isFocused ? focusedIconName : defaultIconName}
              className={`input-icon ${isFocused ? "focused-icon" : ""}`}
            />
          )
        ) : (
          customIcon
        )}
        {counter && counter}
      </div>
    </div>
  );
};

// InputWithIcon.propTypes = {
//   focusedIconName: PropTypes.string.isRequired,
//   defaultIconName: PropTypes.string.isRequired,
//   placeholder: PropTypes.string,
//   className: PropTypes.string,
//   label: PropTypes.string, // New prop for the label
// };

// InputWithIcon.defaultProps = {
//   placeholder: "Enter your text",
//   className: "",
//   label: "", // Default label is empty
// };

export default Input;
