import React from "react";
import PropTypes from "prop-types";
import "../../styles/main.scss";
import "../../styles/designSys.scss";
import HoverIcon from "../atom/Icon";
import BtnDef from "../atom/Btn";

function SubCard({
  icon,
  title,
  content,
  category,
  tags,
  className,
  image,
  link,
}) {
  return (
    <div className="work-card-hero">
      <div className={`subsection-illus-box ${className}`}>
        {image && <img src={image} alt="Illustration" />}
      </div>
      <div className="subsection-text-box">
        <span className="category">{category}</span>
        <span>{title}</span>
        <div
          className="sub-text_box"
          dangerouslySetInnerHTML={{ __html: content }}
        />
        <div className="tags-box">
          {tags && tags.map((tag, index) => <span key={index}>{tag}</span>)}
        </div>
        <BtnDef
          className="btn_casestudy"
          onClick={() => {
            window.location = link;
          }}
        >
          View Details
          <HoverIcon
            className=""
            icon="right_arrow"
            iconHover="right_arrow_ani"
          />
        </BtnDef>
      </div>
    </div>
  );
}

SubCard.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string),
  icon: PropTypes.string,
  title: PropTypes.string,
  category: PropTypes.string,
  content: PropTypes.string,
  className: PropTypes.string,
  image: PropTypes.string, // New prop for image
  link: PropTypes.string, // New prop for link
};

export default SubCard;
