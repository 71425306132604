import React from "react";
import PropTypes from "prop-types";
import "../../styles/main.scss";
import "../../styles/designSys.scss";
import HoverIcon from "../atom/Icon";

function TeamCard({ icon, name, role, subRole }) {
  return (
    <div className="team-box">
      <div className="team-illus-box">
        <CustomIcon icon={icon} />
      </div>
      <div className="team-text-box">
      <h6>{name}</h6>
      <h7 >{role}</h7>
      <h8 >{subRole}</h8>
      </div>
    </div>
  );
}

function CustomIcon({ icon }) {
  return <HoverIcon icon={icon} />;
}

export default TeamCard;
