import React from "react";
import PropTypes from "prop-types";
import "../../styles/main.scss";
import "../../styles/designSys.scss";
import HoverIcon from "../atom/Icon";

function TopSection({ icon, title, content }) {
  return (
    <div className="section-hero">
      <div className="slogan-box">
          <span>革新</span>と<span>成功</span>を可能にする美しい<span>ユーザー中心</span>のデザインと体験を提供します! 
      </div>
      <div className="illus-box">
        <CustomIcon icon={icon} />
      </div>
        <h5>UXデザイン/UIデザイン専門家</h5>
    </div>
  );
}

function CustomIcon({ icon }) {
  return <HoverIcon icon={icon} />;
}

export default TopSection;
