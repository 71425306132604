// import CustomIcon from "../data/IconCompo";
import "../styles/main.scss";
import "../styles/designSys.scss";
import Button from "../ui/atom/Button";
import Table from "../ui/atom/Table";
import BtnDef from "../ui/atom/Btn";
import HoverIcon from "../ui/atom/Icon";

function Home() {
  return (
    <div className="home">
      <Button
        link={"/"}
        text="DS-BUTTONS"
        className={"text_only_btn big_title"}
        onClick={() => console.log("clicked")}
      />
      <Table title="BUTTONS" title2=" Default - Big - Small">
        {/* // - - - - - - - - - Primary Button - - - - - - - - - */}
        <tr>
          <td className="td_one">Primary Buttons</td>
          <td className="td_two">
            <BtnDef link="/">
              <span>BUTTONS</span>
              <HoverIcon icon="clear" iconHover="clear_ani" />
            </BtnDef>
          </td>
          <td className="td_two">
            <BtnDef className={"big"} link="/">
            <HoverIcon icon="clear" iconHover="clear_ani" />
              <span>BUTTONS</span>
            </BtnDef>
          </td>
          <td className="td_two">
          <BtnDef className={"small"} link="/">
            <HoverIcon icon="clear" iconHover="clear_ani" />
              <span>BUTTONS</span>
            </BtnDef>
          </td>
        </tr>
        {/* // - - - - - - - - - Secondary Button - - - - - - - - - */}
        <tr>
          <td className="td_one">Secondary Buttons</td>
          <td className="td_two">
          <BtnDef className={"btn_sec"} link="/">
            <HoverIcon icon="clear" iconHover="clear_ani" />
              <span>BUTTONS</span>
            </BtnDef>
          </td>
          <td className="td_two">
          <BtnDef className={"btn_sec big"} link="/">
            <HoverIcon icon="clear" iconHover="clear_ani" />
              <span>BUTTONS</span>
            </BtnDef>
          </td>
          <td className="td_two">
          <BtnDef className={"btn_sec small"} link="/">
            <HoverIcon icon="clear" iconHover="clear_ani" />
              <span>BUTTONS</span>
            </BtnDef>
          </td>
        </tr>
        {/* // - - - - - - - - - Thirdly Button - - - - - - - - - */}
        <tr>
          <td className="td_one">Thirdly Buttons</td>
          <td className="td_two">
          <BtnDef className={"btn_thi"} link="/">
            <HoverIcon icon="clear"  iconHover="clear_ani"/>
              <span>BUTTONS</span>
              <HoverIcon icon="trash" iconHover="trash_ani" />

            </BtnDef>
          </td>
          <td className="td_two">
          <BtnDef className={"btn_thi big"} link="/">
            <HoverIcon icon="clear" iconHover="clear_ani" />
              <span>BUTTONS</span>
              <HoverIcon icon="trash" iconHover="trash_ani" />

            </BtnDef>
          </td>
          <td className="td_two">
          <BtnDef className={"btn_thi small"} link="/">
            <HoverIcon icon="clear" iconHover="clear_ani" />
              <span>BUTTONS</span>
              <HoverIcon icon="trash" iconHover="trash_ani" />

            </BtnDef>
          </td>
        </tr>
        {/* // - - - - - - - - - Disactived Button - - - - - - - - - */}
        <tr>
          <td className="td_one">Disactived Buttons</td>
          <td className="td_two">
          <BtnDef className={"btn_off"} link="/">
            <span>NOT</span>
            <HoverIcon icon="clear"  />
             <span>ACTIVE</span>
            </BtnDef>
          </td>
          <td className="td_two">
          <BtnDef className={"btn_off big"} link="/">
          <span>NOT</span>
            <HoverIcon icon="clear"  />
            <span>ACTIVE</span>
            </BtnDef>
          </td>
          <td className="td_two">
          <BtnDef className={"btn_off small"} link="/">
          <span>NOT</span>
            <HoverIcon icon="clear"  />
            <span>ACTIVE</span>
            </BtnDef>
          </td>
        </tr>
        {/* // - - - - - - - - - Icon Button - - - - - - - - - */}
        <tr>
          <td className="td_one">Icon Buttons</td>
          <td className="td_two">
          <BtnDef className={"icn_btn"} link="/">
            <HoverIcon icon="clear" iconHover="clear_ani" />
            </BtnDef>
          </td>
          <td className="td_two">
          <BtnDef className={"icn_btn big"} link="/">
            <HoverIcon icon="clear" iconHover="clear_ani" />
            </BtnDef>
          </td>
          <td className="td_two">
          <BtnDef className={"icn_btn small"} link="/">
            <HoverIcon icon="clear" iconHover="clear_ani" />
            </BtnDef>
          </td>
        </tr>
        {/* // - - - - - - - - - Icon Only Button - - - - - - - - - */}
        <tr>
          <td className="td_one">Icon Only Buttons</td>
          <td className="td_two">
            <Button
              icon="clear"
              iconHover="clear_ani"
              className={"icn_only_btn"}
              onClick={() => console.log("clicked")}
            />
          </td>
          <td className="td_two">
            <Button
              icon="clear"
              iconHover="clear_ani"
              className={"icn_only_btn big"}
              onClick={() => console.log("clicked")}
            />
          </td>
          <td className="td_two">
            <Button
              icon="clear"
              iconHover="clear_ani"
              className={"icn_only_btn small"}
              onClick={() => console.log("clicked")}
            />
          </td>
        </tr>
        {/* // - - - - - - - - - Text Button - - - - - - - - - */}
        <tr>
          <td className="td_one">Text Buttons</td>
          <td className="td_two">
          <BtnDef className={"text_btn"} link="/">
              <span>BUTTONS</span>
            </BtnDef>
          </td>
          <td className="td_two">
          <BtnDef className={"text_btn big"} link="/">
              <span>BUTTONS</span>
            </BtnDef>
          </td>
          <td className="td_two">
          <BtnDef className={"text_btn small"} link="/">
              <span>BUTTONS</span>
            </BtnDef>
          </td>
        </tr>
        {/* // - - - - - - - - - Text Only Button - - - - - - - - - */}
        <tr>
          <td className="td_one">Text Buttons</td>
          <td className="td_two">
          <BtnDef className={"text_only_btn"} link="/">
              <span>BUTTONS</span>
            </BtnDef>
          </td>
          <td className="td_two">
          <BtnDef className={"text_only_btn big"} link="/">
              <span>BUTTONS</span>
            </BtnDef>
          </td>
          <td className="td_two">
          <BtnDef className={"text_only_btn small"} link="/">
              <span>BUTTONS</span>
            </BtnDef>
          </td>
        </tr>
      </Table>
    </div>
  );
}

export default Home;
