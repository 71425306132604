import React from "react";
import PropTypes from "prop-types";
import "../../styles/main.scss";
import "../../styles/designSys.scss";
import HoverIcon from "../atom/Icon";

function SubCard({ icon, title, content, category, tags, className }) {
  return (
    <div className={`subsection-top-hero ${className}`}>
      <div className="subsection-illus-box">
        <CustomIcon icon={icon} />
      </div>
      <div className="subsection-text-box">
        <span className="category">{category}</span>
        <span>{title}</span>
        <div
          className="sub-text_box"
          dangerouslySetInnerHTML={{ __html: content }}
        />
        <div className="tags-box">
          {tags && tags.map((tag, index) => <span key={index}>{tag}</span>)}
        </div>
      </div>
    </div>
  );
}

SubCard.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string),
  icon: PropTypes.string,
  title: PropTypes.string,
  category: PropTypes.string,
  content: PropTypes.string,
  children: PropTypes.node,
};

function CustomIcon({ icon }) {
  return <HoverIcon icon={icon} />;
}

export default SubCard;
