// import CustomIcon from "../data/IconCompo";
import "../styles/main.scss";
import "../styles/designSys.scss";
import React, { useState } from "react";
import InputSelectData from "../ui/atom/InputSelectData";
import Header from "../ui/comp/Header";
import SectionCard from "../ui/comp/SectionCard";
import TopSection from "../ui/comp/TopSection";
import SubSectionCard from "../ui/comp/SubSectionCard";
import SubCard from "../ui/comp/SubCard";
import WorkCard from "../ui/comp/WorkCard";
import SloganCrad from "../ui/comp/SloganCard";
import WorkStyleCard from "../ui/comp/WorkStyleCard";
import Footer from "../ui/comp/Footer";
import TeamCard from "../ui/comp/TeamCard";
import WorkTemp from "../ui/comp/WorkTemp";
import WorkTitle from "../ui/atom/works/workTitle";
import WorkDetailCard from "../ui/atom/works/WorkDetailCard";

function Home({ setMobileMenu, mobileMenu, scrollpos, setScrollpos }) {
  return (
    <div className="work-details-hero samansa-details-hero">
      {/* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: HEADER :::::::: */}
      <Header
        className="header-light"
        setMobileMenu={(res) => setMobileMenu(res)}
        mobileMenu={mobileMenu}
        scrollpos={scrollpos}
        setScrollpos={(res) => setScrollpos(res)}
      />
      {/* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: WORK DETAILS :::::::: */}

      <WorkTitle
        // icon="meditel_logo"
        title="Samansa"
        category="Entertainment"
        content="
        世界中のクリエイターと直接契約を交わし、様々なショート映画を配信している映像配信サービスです。<br/>
        一作品ごとにクリエイターと契約を結んでおり、すべての作品が1〜20分程度の短編です。<br/>
        移動時間やスキマ時間など、好きな時間に好きな場所でハイクオリティな映画を楽しむことができます。        
        </p>"
        image="samansa_02.png"
        tags={["UX Design", "UI Design"]}
        tags2={["Web", "App"]}
        tags3={["Wire-frame", "Usability Test"]}
      />
      <WorkDetailCard
        icon=""
        className={"text-only"}
        title="The Challenge"
        content="<p>
              アプリとウェブのUIデザインを更新する役割を担い、非常に短い期間内で作業を進める必要がありました。<br/>
              また、すべての必要なUXメソッドやワークショップなどを実行する時間が限られていたため、プロジェクトの課題や改善点を把握する時間が不足していました。<br/>
              さらに、クライアントの希望の期間に間に合わせることも課題でした。              
              </p>"
        title2="The Solution"
        content2="<p>
              全ての利用可能な時間を最大限に活用するため、プロジェクトを理解し、問題を定義するために最低限の時間でステークホルダーとのインタビューを行い、既存プロダクトのUXを評価するためのオーディットを実施しました。<br/>
              UIデザインの作業を開始する前に、プロダクトで気になるUXの点をクライアントに提案しました。<br/>
              このアプローチにより、制限された時間内で効果的なUIデザインを実現し、UXの向上に向けた具体的なアクションを実施することができました。              
              </p>"
      />

      <WorkDetailCard
        className="color-01"
        category="Design"
        title="UI Design"
        content="<p>
              アプリとウェブの両プラットフォームで直感的で視覚的に魅力的なユーザーインターフェースを作成することに焦点を当てました。<br/>
              オンボーディング画面では、ユーザーをシームレスにプラットフォームに案内するために設計し、映画検索機能はナビゲーションの容易さと所望のコンテンツへの迅速なアクセスを最適化しました。<br/>
              映画視聴画面では、スムーズな視聴体験のために明確なレイアウトとコントロールを重視し、ビジュアルの快適性を向上させるためにダークモードオプションを提供しました。<br/>
              映画情報セクションでは、簡潔かつ包括的な詳細を提供し、ユーザーがすべての必要な情報を手に入れることができるようにしました。<br/>
              さらに、コメントと支払いセクションは、ユーザーの参加と満足を促進するために、シームレスなインタラクションを実現するために最適化されました。              
              </p>"
        image="samansa_ui@2x.png"
      />
      <WorkDetailCard
        className="color-02"
        category="Design"
        title="Before / After"
        content="<p>
              ユーザーエクスペリエンスを向上させるために、古いデザインのレイアウトやカラースキームの不一致などの使いやすさの問題に対処しました。<br/>
              更新されたデザインでは、より清潔なレイアウト、一貫したビジュアル要素、改善されたナビゲーションフローを導入しました。<br/>
              UIコンポーネントを洗練させ、ユーザーの好みに合わせて整えることで、統一されたユーザーフレンドリーなエクスペリエンスを提供し、ユーザー満足度と関与度を向上させました。              
              </p>"
        image="samansa_ui2@2x.png"
      />

      {/* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: HEADER :::::::: */}
      <Footer />
    </div>
  );
}

export default Home;
