// import CustomIcon from "../data/IconCompo";
import "../styles/main.scss";
import "../styles/designSys.scss";
import React, { useState } from "react";
import InputSelectData from "../ui/atom/InputSelectData";
import Header from "../ui/comp/Header";
import SectionCard from "../ui/comp/SectionCard";
import TopSection from "../ui/comp/TopSection";
import SubSectionCard from "../ui/comp/SubSectionCard";
import SubCard from "../ui/comp/SubCard";
import WorkCard from "../ui/comp/WorkCard";
import SloganCrad from "../ui/comp/SloganCard";
import WorkStyleCard from "../ui/comp/WorkStyleCard";
import Footer from "../ui/comp/Footer";
import TeamCard from "../ui/comp/TeamCard";
import WorkTemp from "../ui/comp/WorkTemp";
import WorkTitle from "../ui/atom/works/workTitle";
import WorkDetailCard from "../ui/atom/works/WorkDetailCard";

function Home({ setMobileMenu, mobileMenu, scrollpos, setScrollpos }) {
  return (
    <div className="work-details-hero alphanote-details-hero">
      {/* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: HEADER :::::::: */}
      <Header
        className="header-light"
        setMobileMenu={(res) => setMobileMenu(res)}
        mobileMenu={mobileMenu}
        scrollpos={scrollpos}
        setScrollpos={(res) => setScrollpos(res)}
      />
      {/* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: WORK DETAILS :::::::: */}

      <WorkTitle
        title="Alphanote"
        category="Entertainment"
        content="
        <p>
        Alphanoteは革新的な無料の音楽ストリーミングプラットフォームであり、独立したミュージシャンを支援し、地域の音楽の需要に応えることを目指しています。</br>
        この新しい音楽ストリーミングプラットフォームは、音楽クリエイター、リスナー、ファンを最高の音楽体験でつなぎ、音楽業界を再活性化することを目指しています。
        </p>"
        image="alphanote_top02@2x.png"
        tags={["UX Design", "UI Design", "Branding"]}
        tags2={["Web", "App", "Creative Direction"]}
        tags3={[
          "Research",
          "Persona",
          "Customer Journey",
          "Userflow",
          "Wireframe",
          "Prototype",
          "Usability Test",
          "その他",
        ]}
      />
      <WorkDetailCard
        className={"text-only"}
        title="The Challenge"
        content="<p>
              Alphanoteの直感的で魅力的なユーザーエクスペリエンス（UX）とユーザーインターフェース（UI）を設計することは、独立したミュージシャンが露出を求めるニーズと、シームレスな音楽ストリーミング体験を求めるリスナーのニーズをバランスさせるという課題があります。
                  </p>
                  <p>
                  さらに、活気あるミュージシャンファンコミュニティの構築、ユーザーフレンドリーな音楽プレイヤーの統合、イベントの効率的な管理、および他の機能の潜在的な組み込みなど、さらなる課題が生じます。                    
              </p>"
        title2="The Solution"
        content2="<p>
              ユーザーのニーズを優先し、ロバストなミュージシャンプロフィール、専用のコミュニティセクション、リスナー向けの直感的なナビゲーション、シームレスな音楽再生、イベント管理の効率化、ライブストリーミングや視聴者フィードバック、キュレーションされた発見などの追加機能を活用して、活気あふれる包括的な音楽コミュニティを育成します。
                  </p>
                  <p>
                  これらの要素は、ユーザー調査、ワイヤーフレーム作成、プロトタイピング、使いやすさテスト、反復設計を組み合わせて、ミュージシャンとリスナーの両方にとってハーモニーのある満足のいく体験を確保します。                    
              </p>"
      />

      <WorkDetailCard
        className="color-03"
        category="Discovry & Define"
        title="WIREFRAME & TESTING"
        content="<p>
              ワイヤーフレームはAlphanoteのユーザーインターフェースデザインをテストし、検証するための重要なツールです。ユーザーテストでは、スムーズなナビゲーションと機能の確認を行います。クライアントや関係者からのフィードバックを受けて、デザインを改善します
                  </p>
                  <p>
                  ddまた、提案されたソリューションがプロジェクト目標を満たす効果を検証します。反復的な改善を通じて、ワイヤーフレームはユーザーセントリックな最終デザインの開発を導きます。                    
              </p>"
        image="alphanote_wf@2x.png"
      />
      <WorkDetailCard
        className="color-01"
        image2="alphanote_03.png"
        category="03.DESIGN"
        title="UI Design "
        content="<p>
        このUIデザインは、ミュージシャンとそのファンの両方のニーズを考慮し、2つのプラットフォーム向けに異なるフローと独自の機能を持つデザインを作成しました。<br/>
        ミュージシャン向けのUIでは、オンボーディング、音楽ストリームの管理、個人スペースの提供など、アーティストが自分の音楽を管理し、ファンとのインタラクションを楽しむための機能を重視しました。<br/>
        一方、ファン向けのUIでは、新しい音楽を探索し、アーティストとの交流を深めるための機能に焦点を当てました。              
        </p>"
        image="alphanote_05@2x.png"
      />
      <WorkDetailCard
        //  icon="illus_01"

        category="Define"
        title="Persona & Customer Journey "
        content="<p>
              サラは、アマチュアミュージシャンを代表するペルソナであり、デザインの決定やユーザーエクスペリエンスを導きます。サラのニーズや課題を理解することで、Alphanoteがターゲットユーザーの要件を満たすことを確認します。
              
                  </p>
                  <p>
                  プロジェクト全体を通じて、サラはデザインの選択を検証し、利用性を評価するための参照ポイントとして機能し、Alphanoteがアマチュアミュージシャンを効果的にサポートし、音楽コミュニティ内で他者とつながることを保証します。                  
              </p>
              <br/>
              <p>
              プロジェクトケーススタディでは、クライアントの旅を使用して、Alphanoteとのユーザーの相互作用方法を理解し、プロジェクト全体をガイドします。                  
          </p>
          <p>
          各ステップでのユーザーの行動、苦情、動機を分析することで、解決策が効果的にユーザーのニーズを満たすことを確認し、よりユーザーセントリックなプラットフォーム体験を実現します。                  
      </p>
  "
        image="alphanote_persona@2x.png"
      />

      <WorkDetailCard
        //  icon="illus_01"
        image2="alphanote_brand_top@2x.png"
        category="03.DESIGN"
        title="Branding"
        content="<p>
              Alphanoteの本質を捉えたダイナミックでインパクトのあるロゴデザインです。音符と「a」の文字を組み合わせ、音楽と革新を象徴しています。
                  </p>
                  <p>
                  明るい色合いは、多様な音楽体験を表現し、エネルギーと創造性を想起させます。                   
              </p>
              <p>
              オリジナルでモダンなアイコンセットが作成され、ブランドの視覚的アイデンティティを向上させています。Alphanoteの使命を反映し、新鮮で現代的な魅力を表現しています。                   
          </p>
          "
        image="alphanote_brand.svg"
      />

      {/* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: HEADER :::::::: */}
      <Footer />
    </div>
  );
}

export default Home;
