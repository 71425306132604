// import CustomIcon from "../data/IconCompo";
import "../styles/main.scss";
import "../styles/designSys.scss";
import Button from "../ui/atom/Button";
import Table from "../ui/atom/Table";
import Input from "../ui/atom/Input";

function Home() {
  return (
    <div className="home">
      <Button
        link={"/"}
        text2="DS-INPUTS"
        className={"text_only_btn big_title"}
        onClick={() => console.log("clicked")}
      />
      <Table title="INPUTS">
        {/* // - - - - - - - - - Primary Button - - - - - - - - - */}
        <tr>
          <td className="td_one">Primary Input</td>
          <td className="td_two">
            <Input
              placeholder="Placeholder"
              label="Name"
              clearIcon={false}
              icon={"designer_ani"}
              iconHover={"designer"}
              disable={false}
            />
          </td>
        </tr>
      </Table>
    </div>
  );
}

export default Home;
