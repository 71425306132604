// import CustomIcon from "../data/IconCompo";
import "../styles/main.scss";
import "../styles/designSys.scss";
import React, { useState } from "react";
import InputSelectData from "../ui/atom/InputSelectData";
import Header from "../ui/comp/Header";
import SectionCard from "../ui/comp/SectionCard";
import TopSection from "../ui/comp/TopSection";
import SubSectionCard from "../ui/comp/SubSectionCard";
import SubCard from "../ui/comp/SubCard";
import WorkCard from "../ui/comp/WorkCard";
import SloganCrad from "../ui/comp/SloganCard";
import WorkStyleCard from "../ui/comp/WorkStyleCard";
import Footer from "../ui/comp/Footer";
import TeamCard from "../ui/comp/TeamCard";
import WorkTemp from "../ui/comp/WorkTemp";
import WorkTitle from "../ui/atom/works/workTitle";
import WorkDetailCard from "../ui/atom/works/WorkDetailCard";

function Home({ setMobileMenu, mobileMenu, scrollpos, setScrollpos }) {
  return (
    <div className="work-details-hero monorevo-details-hero">
      {/* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: HEADER :::::::: */}
      <Header
        className="header-light"
        setMobileMenu={(res) => setMobileMenu(res)}
        mobileMenu={mobileMenu}
        scrollpos={scrollpos}
        setScrollpos={(res) => setScrollpos(res)}
      />
      {/* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: WORK DETAILS :::::::: */}

      <WorkTitle
        title="Monorevo"
        category="販売/生産管理"
        content="
        <p>
        製造業に特化したクラウドアプリケーションで、工場の製造プロセスを容易に可視化できます。特に、「少量多品種」×「短納期」が要求される製造業現場において、DXを駆使して効率を飛躍的に向上させるサービスです。工程管理、在庫管理、受発注管理など、あらゆる業務を統合管理でき、製造業現場の円滑な運営を支援します。                </p>"
        image="monorevo_top@2x.png"
        tags={["UX Design", "UI Design"]}
        tags2={["Web Application", "Creative Direction"]}
        tags3={[
          "Research",
          "Customer Journey",
          "Userflow",
          "Wireframe",
          "Prototype",
          "Usability Test",
          "その他",
        ]}
      />
      <WorkDetailCard
        className="color-01"
        icon=""
        // className={"text-only"}
        title="The Challenge"
        content="<p>
              MonoRevoプロジェクトに取り組むUXUIデザイナーとして、我々は製造業特有の多岐にわたる課題に直面しました。<br/>
              プロダクトプロセスの一部がまだアナログであること、必要な機能の欠如、意味を持たない機能など、複雑な状況がありました。<br/>加えて、製造業は短い納期内で多様なプロダクトタイプを管理する必要があり、この要求に適切に対処することが課題でした。<br/>
              さらに、異なる生産段階間の可視性と調整の不足が、効率的なワークフローを妨げる重要な障壁となっていました。                 
               </p>
             "
        title2="The Solution"
        content2="<p>
              これらの課題に対処するために、包括的なUXUIソリューションを開発しました。まず第一に、不足している機能を特定し、プロダクトに組み込むことでプロダクトの使いやすさと機能性を向上させました。<br/>
              次に、アナログなプロセスをデジタル化し、効率的なユーザーエクスペリエンスを提供するための直感的なインターフェースを設計しました。<br/>
              さらに、我々の解決策は、製造プロセス全体をリアルタイムで可視化し、生産管理を最適化することで、製造業の課題に迅速かつ効果的に対処することを可能にしました。                  
              </p>

              "
      />

      <WorkDetailCard
        className="color-03"
        category="Define"
        title="UserFlow"
        content="<p>
        ユーザーフローとIAはプロジェクトの構築の最初の段階で作成しました。<br/>
        プロダクトが複雑であったため、すべての側面を深く理解し、そのプロセスフローと既存の構造を分析する必要がありました。<br/>
        ユーザーフローでは、ユーザーがプロダクト内でどのように移動し、インタラクトするかを視覚的に示しました。<br/>
        IAでは、プロダクトの構造とナビゲーションを整理し、ユーザーが情報を見つけやすくするための階層構造を設計しました。              
        </p>"
        image="monorevo_userflow@2x.png"
      />
      <WorkDetailCard
        className="color-03"
        category="Define"
        title="Design Sprint"
        content="<p>
        デザインスプリントは、プロダクトの主要な機能に関してクライアントと実施したものです。<br/>
        このセッションでは、製造業に特化したクラウドアプリケーションにおける重要な機能に焦点を当て、アイデアの共有、詳細なディスカッション、プロトタイプの作成、およびテストを行いました。
        クライアントとの協力のもと、プロダクトの機能やユーザーエクスペリエンスを改善するための具体的なアプローチを議論し、進化させました。              
        </p>"
        image="monorevo_designsprint@2x.png"
      />
      <WorkDetailCard
        className="color-02"
        image2="alphanote_03.png"
        category="Define"
        title="Wireframe"
        content="<p>
        ワイヤーフレームは、プロダクトの各機能やサービスの一部に対して、UXUIデザイナーとして私が開発した包括的なソリューションを視覚化するために重要なツールでした。<br/>
        プロダクトの複雑さから、それぞれの機能やサービスのためにワイヤーフレームを作成し、その後ユーザーによるテストを行うことで、プロダクトが適切に機能し、使いやすいことを確認しました。<br/>
        ワイヤーフレームの使用により、プロダクトの機能性と使いやすさを向上させ、製造業特有の課題に効果的に対処することができました。              
        </p>"
        image="monorevo_wf@2x.png"
      />
      <WorkDetailCard
        className="color-01"
        category="Design"
        title="UI Design"
        content="<p>

        UIデザインは、ユーザーエクスペリエンスを向上させるために私が行った重要な作業の一つでした。<br/>
        特にタスク管理の複雑さは最も挑戦的であり、様々なケースに対処し、ユーザーがタスクを簡単に追加、表示、編集できるようにする必要がありました。<br/>
        そのため、タスク管理のUIを設計する際には、直感的で使いやすいインターフェースを実現するために、情報の整理と可視化に重点を置きました。<br/>
        また、他の機能に関しても、ユーザーが効率的に操作できるようなデザインを追求し、プロダクト全体の一貫性を保ちながら、最良のユーザーエクスペリエンスを提供することに注力しました。      
        </p>
  "
        image="monorevo_ui@2x.png"
      />

      <WorkDetailCard
        className="color-03"
        image2="alphanote_brand_top@2x.png"
        category="DESIGN"
        title="Design System"
        content="<p>

        プロジェクトのデザインシステムは、プロダクトの一貫性を確保し、効率的な開発と拡張を可能にするために作成しました。<br/>
        プロダクトの複雑さを考慮し、異なる機能やコンポーネントの間で一貫性を維持することで、ユーザーエクスペリエンスの向上とスケーラビリティの確保を実現しました。          
        </p>
          "
        image="monorevo_ds@2x.png"
      />

      {/* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: HEADER :::::::: */}
      <Footer />
    </div>
  );
}

export default Home;
