// import CustomIcon from "../data/IconCompo";
import "../styles/main.scss";
import "../styles/designSys.scss";
import React, { useState } from "react";
import InputSelectData from "../ui/atom/InputSelectData";
import Header from "../ui/comp/Header";
import SectionCard from "../ui/comp/SectionCard";
import TopSection from "../ui/comp/TopSection";
import SubSectionCard from "../ui/comp/SubSectionCard";
import SubCard from "../ui/comp/SubCard";
import WorkCard from "../ui/comp/WorkCard";
import SloganCrad from "../ui/comp/SloganCard";
import WorkStyleCard from "../ui/comp/WorkStyleCard";
import Footer from "../ui/comp/Footer";
import TeamCard from "../ui/comp/TeamCard";
import WorkTemp from "../ui/comp/WorkTemp";
import WorkTitle from "../ui/atom/works/workTitle";
import WorkDetailCard from "../ui/atom/works/WorkDetailCard";

function Home({ setMobileMenu, mobileMenu, scrollpos, setScrollpos }) {
  return (
    <div className="work-details-hero noones-details-hero">
      {/* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: HEADER :::::::: */}
      <Header
        className="header-dark"
        setMobileMenu={(res) => setMobileMenu(res)}
        mobileMenu={mobileMenu}
        scrollpos={scrollpos}
        setScrollpos={(res) => setScrollpos(res)}
      />
      {/* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: WORK DETAILS :::::::: */}

      <WorkTitle
        // icon="meditel_logo"
        title="Noones"
        category="Entertainment"
        content="
       <p>
       NoOnesは、人々を世界中の会話（チャット）と金融システム（支払い）に結びつけ、力を与える金融コミュニケーションスーパーアプリです。
       ビットコインウォレットを使って価値を保存し、誰でも自由にメッセージを送信したり、マーケットプレイスで約250の支払い方法で取引したり、ピアツーピアで支払いを行ったりすることができます。      
        </p>"
        image="noones_top@2x.png"
        tags={["Strategy", "UX Design", "UI Design", "Branding"]}
        tags2={["Web", "App", "Creative Direction", "Graphic Design"]}
        tags3={[
          "Interview",
          "Research",
          "Persona",
          "Journey Map",
          "Design Sprint",
          "Wire-frame",
          "Usability Test",
        ]}
      />
      <WorkDetailCard
        icon=""
        className={"text-only"}
        title="The Challenge"
        content="
              <p>
              プロジェクトには、チャット、グループ、およびイベント機能の追加が求められており、既存のデザインスタイルである「ピクセルアート」スタイルを導入する必要がありました。<br/>
              さらに、これらの機能を適用する際に、ユーザビリティと使いやすさを確保する必要がありました。この中で、既存の特徴との一貫性とバランスを保つことも課題でした。              
              </p>"
        title2="The Solution"
        content2="<p>
              これらの課題に対処するために、まずチャット、グループ、およびイベント機能を追加する際に、ユーザーの利便性と直感性を重視したUIデザインを採用しました。<br/>
              ピクセルアートスタイルを導入する際には、ユーザーの視覚的な楽しさを提供するためにデザインの細部に工夫を凝らしましたが、同時にユーザーエクスペリエンスに影響を与えないようにしました。
              さらに、一貫性とバランスを保つために、既存のデザインパターンや色の選択に特に注意を払い、新しい機能をスムーズに統合しました。              
              </p>"
      />

      <WorkDetailCard
        className="color-03"
        category="Design"
        title="UI Design"
        content="<p>
              このUIデザインでは、ピクセルアートスタイルを活用し、ユーザーに楽しさと視覚的な興奮を提供しました。<br/>
              機能の配置やデザインの一貫性を考慮しながら、ユーザーが直感的に操作できるようなインターフェースを設計しました。<br/>
              既存のデザインシステムのカラーパレットを活用しつつ、新機能に対応するために新しいアイコンセットを作成しました。<br/>
              カラーパレットやアイコンの選択にも細心の注意を払い、ユーザーエクスペリエンスを向上させるために細部までこだわりました。              
              </p>"
        image="noones_ui@2x.png"
      />
      <WorkDetailCard
        className="color-02"
        image2="test5.png"
        category="Define"
        title="Wirefame"
        content="<p>
              Lo-Fiのワイヤーフレームは、コンテンツの配置と機能の配置を確認するためにクライアントとテストするのに役立ちました。<br/>
              また、ワイヤーフレームを使用して、ユーザーエクスペリエンスの全体的な流れを検討し、問題を発見して修正しました。<br/>
              最終的に、ワイヤーフレームを通じて、プロジェクトの方向性を確認し、クライアントとのコミュニケーションを促進しました。              
              </p>"
        image="noones_wf@2x.png"
      />

      {/* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: HEADER :::::::: */}
      <Footer />
    </div>
  );
}

export default Home;
