// import CustomIcon from "../data/IconCompo";
import "../styles/main.scss";
import "../styles/designSys.scss";
import React, { useState } from "react";
import InputSelectData from "../ui/atom/InputSelectData";
import Header from "../ui/comp/Header";
import SectionCard from "../ui/comp/SectionCard";
import TopSection from "../ui/comp/TopSection";
import SubSectionCard from "../ui/comp/SubSectionCard";
import SubCard from "../ui/comp/SubCard";
import WorkCard from "../ui/comp/WorkCard";
import SloganCrad from "../ui/comp/SloganCard";
import WorkStyleCard from "../ui/comp/WorkStyleCard";
import Footer from "../ui/comp/Footer";
import TeamCard from "../ui/comp/TeamCard";
import WorkTemp from "../ui/comp/WorkTemp";
import WorkTitle from "../ui/atom/works/workTitle";
import WorkDetailCard from "../ui/atom/works/WorkDetailCard";

function Home({ setMobileMenu, mobileMenu, scrollpos, setScrollpos }) {
  return (
    <div className="work-details-hero meditel-details-hero">
      {/* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: HEADER :::::::: */}
      <Header
        className="header-light"
        setMobileMenu={(res) => setMobileMenu(res)}
        mobileMenu={mobileMenu}
        scrollpos={scrollpos}
        setScrollpos={(res) => setScrollpos(res)}
      />
      {/* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: WORK DETAILS :::::::: */}

      <WorkTitle
        title="Meditel"
        category="Entertainment"
        content="
        患者がスマートフォンアプリを通じて、オンライン診察予約や医師とのテレビ電話による診察、クレジットカードでの診察料の支払い、そして健康データの日々の記録など、様々なサービスを提供しています。<br/>
        また、医師はウェブブラウザを介して、健康に関するアドバイスやテレビ電話による診察などを行うことができ、これにより患者と医師の間でオンライン診療がより身近で簡単に利用できるようになります。        
        </p>"
        image="meditel_top@2x.png"
        tags={["Strategy", "UX Design", "UI Design", "Branding"]}
        tags2={["Web", "App", "Creative Direction", "Graphic Design"]}
        tags3={[
          "Interview",
          "Research",
          "Persona",
          "Journey Map",
          "Design Sprint",
          "Wire-frame",
          "Prototype",
          "Usability Test",
          "ect..",
        ]}
      />
      <WorkDetailCard
        icon=""
        className={"text-only"}
        title="The Challenge"
        content="<p>
              Meditelプロジェクトにおける主なUXUIの課題は、患者アプリ、医師ウェブアプリ、およびクライアント管理システムの3つの異なる部分を同時に取り組むことでした。<br/>
              各プラットフォームは異なるターゲットユーザーを対象としており、それぞれのニーズや課題がありました。さらに、異なるプラットフォーム間で一貫性を保ちつつ、それぞれの個別性も尊重する必要がありました。<br/><br/>
              プロジェクトの規模が大きかったため、予算や時間の制約はありませんでしたが、多様な要素を効果的に統合する方法は、最高のプロダクトを提供するための重要なチャレンジでした。<br/>
              それに対処するために、緻密なUXデザインプロセスを実行し、各プラットフォームに適したデザインを確立しました。
              </p>"
        title2="The Solution"
        content2="<p>

              プロジェクトの改善を開始し、MVVを強化し、ターゲットを明確にするためのディスカバリー調査やインタビューを増やしました。その後、患者と医師の双方に焦点を当てたデザインスプリントセッションやワークショップを実施しました。<br/>
              ワイヤーフレームやプロトタイプを用いて、各機能やアイデアを次の段階に進む前にテストしました。<br/>
              全てのプロダクトとプラットフォーム間で一貫性を維持しつつ、各要素の個別性も尊重しました。さらに、各プラットフォームがそれぞれのターゲットユーザーに適していることを確認するために、ユーザーテストやフィードバックのサイクルを定期的に実施しました。<br/>
              これにより、最終的にはクライアント満足度を向上させ、最高のプロダクトを提供することができました             
               </p>"
      />

      <WorkDetailCard
        className="color-03"
        category="Define"
        title="Wirefarme"
        content="<p>
        ワイヤーフレームは、プロジェクトにおける重要なステップの一つであり、ユーザーインターフェースの基本的な構造や配置を示すために使用されました。<br/>
        各画面の要素や機能の配置を明確にし、デザインの方向性やユーザーのニーズに関する検討を行いました。ワイヤーフレームを使用して、デザインの問題や改善点を特定し、迅速に修正しました。<br/>
        また、ワイヤーフレームをクライアントやチームメンバーと共有し、フィードバックを収集しました。これにより、より使いやすく、効果的な最終的なデザインを実現するための基盤を築くことができました。             
         </p>"
        image="meditel_wf@2x.png"
      />
      <WorkDetailCard
        className="color-01"
        image2="test5.png"
        category="Design"
        title="UI Deisgn"
        content="<p>
        患者アプリや医師のタスク管理など、様々な機能において、ユーザーが直感的に操作できるようなデザインを心掛けました。<br/>
        健康トラッキングやスケジューリング、オンライン審査などの機能は、ユーザーの利便性を最大化するために、使いやすく直感的なUIを追求しました。<br/>
        さらに、カラーパレットやフォント、アイコンなどの要素を選定し、全体的なデザインの一貫性を確保しました。<br/>
        デザインのプロトタイプや最終的なデザインを開発する際には、ユーザビリティテストやフィードバックのループを通じて、ユーザーのニーズやフローの改善点を把握し、適切に反映させました              
        </p>"
        image="meditel_ui@2x.png"
      />
      <WorkDetailCard
        className="color-03"
        image2="test5.png"
        category="Design"
        title="AI / Userflow"
        content="<p>
        Meditelプロジェクトの構築初期段階で作成しました。プロダクトが複雑であったため、すべての側面を深く理解し、プロセスフローと既存の構造を分析する必要がありました。<br/>
        ユーザーフローでは、患者がアプリをどのように利用するかを視覚化し、予約から診察までの流れを明確にしました。<br/>
        患者アプリや医師ウェブアプリの情報アーキテクチャを構築し、機能やコンテンツの配置、ナビゲーションを計画しました。<br/>
        これらのツールを使用して、Meditelの全体的な構造を明確にし、プロジェクトの進行をスムーズにしました。             
         </p>"
        image="meditel_ai@2x.png"
      />

      {/* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: HEADER :::::::: */}
      <Footer />
    </div>
  );
}

export default Home;
