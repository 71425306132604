// BtnDef.js
import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../../styles/main.scss";
import HoverIcon from "./Icon";

export default function BtnDef({ className, onClick, link, children }) {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  // Combined class name logic
  const combinedClassName = Array.isArray(className)
    ? className.join(" ")
    : className;

  // Split children into sections: before and after HoverIcon
  const beforeHoverIcon = [];
  const afterHoverIcon = [];

  let foundHoverIcon = false;

  React.Children.forEach(children, (child) => {
    if (child && child.type === HoverIcon) {
      foundHoverIcon = true;
    }

    if (foundHoverIcon) {
      afterHoverIcon.push(child);
    } else {
      beforeHoverIcon.push(child);
    }
  });

  return (
    <Link
      to={link}
      className={`btn_def ${combinedClassName}`}
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {beforeHoverIcon}
      {React.Children.map(afterHoverIcon, (child, index) =>
        React.cloneElement(child, { key: index, isParentHovered: isHovered })
      )}
    </Link>
  );
}
