// Header.js
import React, { useEffect, useRef, useState } from "react";
import "../../styles/mobileMenu.scss";
import HoverIcon from "../atom/Icon";
import BtnDef from "../atom/Btn";
import { useLocation } from "react-router-dom";
import CustomIcon from "../../data/IconCompo";

export default function MobileMenu({
  className,
  children,
  setScrollpos,
  setMobileMenu,
}) {
  const head = useRef(null);

  const location = useLocation();

  useEffect(() => {
    // Scroll to top whenever the route changes
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <div className={`header-hero-mob ${className}`} ref={head}>
        {children}

        <div className="header-logo_box">
          <div className="logo_button" link="/buttons">
            <HoverIcon
              className="btn_sec"
              icon="juusando_logo"
              iconHover="logo_ani"
            />
            <span>JUUSANDO!</span>
          </div>
        </div>

        <div className="header-nav_box">
          <nav
            className="header-nav"
            onClick={() => {
              if (
                window.location.href.split("/")[3] !== "home" &&
                window.location.href.split("/")[3] !== ""
              ) {
                window.location = "home/about";
              } else {
                setScrollpos(
                  document.querySelector(".about-section").offsetTop - 30
                );
                setMobileMenu(false);
              }
            }}
          >
            ABOUT
          </nav>
          <nav
            className="header-nav"
            onClick={() => {
              if (
                window.location.href.split("/")[3] !== "home" &&
                window.location.href.split("/")[3] !== ""
              ) {
                window.location = "home/service";
              } else {
                setScrollpos(
                  document.querySelector(".service-section").offsetTop - 30
                );
                setMobileMenu(false);
              }
            }}
          >
            SERVICE
          </nav>
          <nav
            className="header-nav "
            onClick={() => {
              if (
                window.location.href.split("/")[3] !== "home" &&
                window.location.href.split("/")[3] !== ""
              ) {
                window.location = "home/style";
              } else {
                setScrollpos(
                  document.querySelector(".style-section").offsetTop - 30
                );
                setMobileMenu(false);
              }
            }}
          >
            STYLE
          </nav>
          <nav
            className="header-nav hide"
            onClick={() => {
              if (
                window.location.href.split("/")[3] !== "home" &&
                window.location.href.split("/")[3] !== ""
              ) {
                window.location = "home/process";
              } else {
                setScrollpos(
                  document.querySelector(".process-section").offsetTop - 30
                );
                setMobileMenu(false);
              }
            }}
          >
            PROCESS
          </nav>
          <nav
            className="header-nav"
            onClick={() => {
              if (
                window.location.href.split("/")[3] !== "home" &&
                window.location.href.split("/")[3] !== ""
              ) {
                window.location = "home/works";
              } else {
                setScrollpos(
                  document.querySelector(".works-section").offsetTop - 30
                );
                setMobileMenu(false);
              }
            }}
          >
            WORKS
          </nav>
        </div>

        <div className="header-right_box">
          <BtnDef
            className="btn_sec"
            onClick={() => {
              if (
                window.location.href.split("/")[3] !== "home" &&
                window.location.href.split("/")[3] !== ""
              ) {
                window.location = "home/contact";
              } else {
                setScrollpos(
                  document.querySelector(".contact-section").offsetTop
                );
                setMobileMenu(false);
              }
            }}
          >
            <h5>CONTACT</h5>
            <HoverIcon className="" icon="mail" iconHover="pinjob_ani" />
          </BtnDef>
        </div>
      </div>
    </>
  );
}
