// BtnDef.js
import "../../../styles/main.scss";
import "../../../styles/works.scss";
import "../../../styles/designSys.scss";
import HoverIcon from "../../atom/Icon";
import BtnDef from "../../atom/Icon";
import ButtonDef from "../../atom/Button";

export default function WorkTitle({
  icon,
  title,
  content,
  category,
  className,
  image,
  tags,
  tags2,
  tags3,
}) {
  return (
    <div className={`work-title-hero ${className}`}>

    <div className="work-title-box">
        <div className="logo">
          <CustomIcon icon={icon} />
        </div>

        <div className="title_box">
          <span className="category">{category}</span>
          <span className="title">{title}</span>
          <div className="text" dangerouslySetInnerHTML={{ __html: content }} />
        </div>

        <div className="tags-hero">
        <div className="tags-box"><h8>OUR ROLES:</h8>{tags && tags.map((tag, index) => <span key={index}>{tag}</span>)}</div>
        <div className="tags-box"><h8>SERVICES: </h8>{tags2 && tags2.map((tag2, index) => <span key={index}>{tag2}</span>)}</div>
        <div className="tags-box"><h8>TOOLS:</h8> {tags3 && tags3.map((tag3, index) => <span key={index}>{tag3}</span>)}</div>
      </div>
{/* 
      <div className="tags-box">
        {tags2 && tags2.map((tag2, index) => <span key={index}>{tag2}</span>)}
      </div> */}

      </div>

      <div className={`top-img-box ${className}`}>
        {image && <img src={image} alt="#" />}
      </div>
    </div>
  );
}

function CustomIcon({ icon }) {
  return <HoverIcon icon={icon} />;
}
