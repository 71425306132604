import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import "../../styles/main.scss";
import CustomIcon from "../../data/IconCompo";

const Select = ({
  options,
  onChange,
  defaultIconName,
  className,
  focusedIconName,
  label,
  selectedOption,
  setSelectedOption,
  placeholder,
  error,
  dropdownSelectClassName,
  disable,
  search,
  clearIcon,
  allOption,
  searchList,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [clickDown, setClickDown] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [placeholders, setPlaceholders] = useState(placeholder);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [showDropDown, setShowDropDown] = useState(false);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(
    allOption ? allOption : null
  );

  const input = useRef(null);
  const optionsContainer = useRef(null);
  useEffect(() => {
    if (!selectedOption) {
      setSearchQuery("");
      if (allOption) {
        setSelectedOption(allOption);
      } else {
        setSelectedOption();
        setPlaceholders();
      }
    }
  }, [selectedOption, allOption]);

  const handleFocus = () => {
    if (!isFocused) {
      setIsFocused(true);
      setShowDropDown(true);
      if (search) {
        input.current.focus();
      }
      setSelectedOptionIndex(0);
    } else {
      setIsFocused(false);
      setShowDropDown(false);
      if (search) {
        input.current.blur();
      }
    }
  };

  const handleBlur = () => {
    // Delay setting isFocused to false to allow the click event on options to register
    if (isFocused) {
      setTimeout(() => {
        setIsFocused(false);
        setShowDropDown(false);
        if (search) {
          input.current.blur();
        }
      }, 20);
    }
    // Adjust the delay as needed
  };

  const handleSelectChange = (option, index) => {
    setSelectedOption(option);
    setSearchQuery("");

    if (option.label == allOption?.label) {
      setSelectedOption();
    }
    // Clear the search query after selection
  };

  const handleKeyDown = (e) => {
    if (showDropDown) {
      switch (e.key) {
        case "ArrowUp":
          e.preventDefault();
          setSelectedOptionIndex((prevIndex) => {
            scrollToOption(
              prevIndex !== null && prevIndex > 0
                ? prevIndex - 1
                : filteredOptions.length - 1
            );
            return prevIndex !== null && prevIndex > 0
              ? prevIndex - 1
              : filteredOptions.length - 1;
          });

          break;
        case "ArrowDown":
          e.preventDefault();
          setSelectedOptionIndex((prevIndex) => {
            scrollToOption(
              prevIndex !== null && prevIndex < filteredOptions.length - 1
                ? prevIndex + 1
                : 0
            );
            return prevIndex !== null && prevIndex < filteredOptions.length - 1
              ? prevIndex + 1
              : 0;
          });
          break;
        case "Enter":
          if (selectedOptionIndex !== null) {
            handleSelectChange(
              filteredOptions[selectedOptionIndex],
              selectedOptionIndex
            );
            handleBlur();
          }
          break;
        default:
          break;
      }
    }
  };

  const scrollToOption = (index) => {
    if (optionsContainer.current) {
      const optionElement = optionsContainer.current.childNodes[index];
      if (optionElement) {
        const containerRect = optionsContainer.current.getBoundingClientRect();
        const optionRect = optionElement.getBoundingClientRect();
        if (optionRect.bottom > containerRect.bottom) {
          optionsContainer.current.scrollTop +=
            optionRect.bottom - containerRect.bottom;
        } else if (optionRect.top < containerRect.top) {
          optionsContainer.current.scrollTop -=
            containerRect.top - optionRect.top;
        }
      }
    }
  };

  useEffect(() => {
    if (searchList) {
      const filtered = searchList.filter((option) => {
        return (
          option.search.toLowerCase().includes(searchQuery.toLowerCase()) ||
          option.kana.toLowerCase().includes(searchQuery.toLowerCase()) ||
          option.en.toLowerCase().includes(searchQuery.toLowerCase())
        );
      });
      setFilteredOptions([{ label: allOption?.label }, ...filtered]);
    } else {
      const filtered = options.filter((option) =>
        option.label.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredOptions([{ label: allOption?.label }, ...filtered]);
    }
  }, [searchQuery, options]);

  useEffect(() => {
    if (selectedOption?.label) {
      setPlaceholders(selectedOption?.label);
    }
  }, [selectedOption]);

  useEffect(() => {
    if (selectedOptionIndex !== null && search) {
    }
  }, [selectedOptionIndex]);
  useEffect(() => {
    if (search) {
      if (disable) {
        input.current.disabled = true;
      } else {
        input.current.disabled = false;
      }
    }
  }, [disable]);
  useEffect(() => {}, [searchQuery, selectedOption]);
  return (
    <div
      className={`input-container ${className} ${isFocused ? "focused" : ""} `}
    >
      {label && <label className="label_box">{label}</label>}
      <div className="input_box">
        <div
          className={`dropdown-select ${isFocused ? "focused" : ""} ${
            error && "input_error"
          } ${dropdownSelectClassName} ${disable ? "input-disabled" : ""}`}
        >
          <div
            className="selected-flag-cont-big"
            tabIndex={search ? undefined : 0}
            onMouseDown={() => {
              !disable && isFocused && handleFocus();
              !disable && isFocused && setClickDown(1);
            }}
            onBlur={() => {
              !disable && handleBlur();
            }}
            onClick={() => {
              !disable && clickDown === 0 && handleFocus();
              !disable && clickDown == 1 && setClickDown(0);
            }}
          >
            <div className="selected-flag-cont">
              <CustomIcon
                iconName={allOption?.iconName}
                className={`right-icon`}
              />

              {search ? (
                <input
                  type="text"
                  id="input"
                  className={`select-search-input ${
                    selectedOption?.label == allOption?.label &&
                    (!isFocused ? "all-option" : "all-option-focused")
                  }`}
                  placeholder={placeholders}
                  value={searchQuery}
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                    setIsFocused(true);
                    setShowDropDown(true);
                    if (search) {
                      input.current.focus();
                    }
                    setSelectedOptionIndex(0);
                  }}
                  onKeyDown={handleKeyDown}
                  ref={input}
                />
              ) : (
                <p>{placeholders}</p>
              )}
            </div>

            {clearIcon &&
            search &&
            (selectedOption?.label != allOption?.label || searchQuery) ? (
              <CustomIcon
                iconName={"clear"}
                className={`input-icon ${isFocused ? "focused-icon" : ""}
            ${selectedOption ? "input-value-icon" : ""} right-icon`}
                onClick={(event) => {
                  if (selectedOption) {
                    event.stopPropagation();
                  }
                  setSelectedOption();
                }}
              />
            ) : (
              <CustomIcon
                iconName={isFocused ? focusedIconName : defaultIconName}
                className={`input-icon  ${
                  isFocused ? "focused-icon" : ""
                } right-icon`}
              />
            )}
          </div>

          {showDropDown && (
            <ul className="options" ref={optionsContainer}>
              {filteredOptions.length == 1 ? (
                <li className={`options-nohover`}>No Option</li>
              ) : (
                filteredOptions.map((option, index) => (
                  <>
                    {search ? (
                      <li
                        key={option.value}
                        onMouseDown={(e) => {
                          handleSelectChange(option, index);
                        }}
                        className={`${
                          selectedOptionIndex === index ? "selected-index" : ""
                        } ${selectedOption === option ? "selected" : ""}  `}
                      >
                        {option.label}
                      </li>
                    ) : (
                      option.label != allOption?.label && (
                        <li
                          key={option.value}
                          onMouseDown={(e) => {
                            handleSelectChange(option, index);
                          }}
                          className={`${
                            selectedOptionIndex === index
                              ? "selected-index"
                              : ""
                          } ${selectedOption === option ? "selected" : ""}  `}
                        >
                          {allOption && (
                            <CustomIcon
                              iconName={allOption?.iconName}
                              className={`right-icon`}
                            />
                          )}
                          {option.label}
                        </li>
                      )
                    )}
                  </>
                ))
              )}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

Select.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  defaultIconName: PropTypes.string,
  className: PropTypes.string,
  focusedIconName: PropTypes.string,
  label: PropTypes.string,
  selectedOption: PropTypes.object,
  setSelectedOption: PropTypes.func,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  flag: PropTypes.bool,
  dropdownSelectClassName: PropTypes.string,
};

export default Select;
