import React from "react";
import "../../styles/main.scss";

const Table = ({ title, title2, children }) => {
  return (
    <div>
      <h2>{title} <span>{title2}</span ></h2>
      
      <table className="table">
        <tbody>
          {children}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
