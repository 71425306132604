
// BtnDef.js
import "../../../styles/main.scss";
import "../../../styles/works.scss";
import "../../../styles/designSys.scss";
import HoverIcon from "../Icon";
import BtnDef from "../Icon";
import ButtonDef from "../Button";

export default function WorkDetailCard({
  icon,
  title,
  content,
  category,
  title2,
  content2,
  category2,
  className,
  image,
  image2,

}) {
  return (
    <div className={`work-detail-hero ${className}`}>
    <div className="work-title-box">
      
        <div className="logo">
        {image2 && <img src={image2} alt="#" />}
          <CustomIcon icon={icon} />
        </div>

        <div className="title_box">
          <span className="category">{category}</span>
          <span className="title">{title}</span>
          <div className="text" dangerouslySetInnerHTML={{ __html: content }} />
          <br/>
          <span className="category">{category2}</span>
          <span className="title">{title2}</span>
          <div className="text" dangerouslySetInnerHTML={{ __html: content2 }} />

        </div>
        
      </div>

      <div className={`top-img-box`}>
        {image && <img src={image} alt="#" />}
      </div>
    </div>
  );
}

function CustomIcon({ icon }) {
  return <HoverIcon icon={icon} />;
}
